import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorPageComponent } from './error-page/error-page.component';
import { SearchResultsPageComponent } from './search-results-page/search-results-page.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { SportIconComponent } from './sport-icon/sport-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TeamIconComponent } from './team-icon/team-icon.component';
import { FlagIconComponent } from './flag-icon/flag-icon.component';
import { HubNavHeaderComponent } from './hub-nav-header/hub-nav-header.component';
import { NextToGoComponent } from './next-to-go/next-to-go.component';
import { RelatedArticlesRowComponent } from './related-articles-row/related-articles-row.component';
import { ShortlistLoginPromptComponent } from './shortlist-login-prompt/shortlist-login-prompt.component';
import { SportSignupBannerComponent } from './sport-signup-banner/sport-signup-banner.component';
import { DesktopNavigationComponent } from './desktop-navigation/desktop-navigation.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { BestOffersComponent } from './best-offers/best-offers.component';
import { PageIntroCopyComponent } from './page-intro-copy/page-intro-copy.component';
import { CopyBlockComponent } from './copy-block/copy-block.component';
import { FaqContainerComponent } from './faq-container/faq-container.component';
import { AdhesiveBannerComponent } from './adhesive-banner/adhesive-banner.component';
import { SubscribeScrollPromptComponent } from './subscribe-scroll-prompt/subscribe-scroll-prompt.component';
import { ArticleImageContainerComponent } from './article-image-container/article-image-container.component';
import { DisplayAdComponent } from './display-ad/display-ad.component';
import { MatchTileComponent } from './match-tile/match-tile.component';
import { GameBarBlockComponent } from './game-bar-block/game-bar-block.component';
import { MiniMatchTileComponent } from './mini-match-tile/mini-match-tile.component';
import { SidebarBlockComponent } from './sidebar-block/sidebar-block.component';
import { CallbackPageComponent } from './callback-page/callback-page.component';
import { ProBannerComponent } from './pro-banner/pro-banner.component';
import { QuickBetPromptComponent } from './quick-bet-prompt/quick-bet-prompt.component';
import { MatchPipesModule } from '../match-pipes/match-pipes.module';
import { BookPipesModule } from '../book-pipes/book-pipes.module';
import { PageTutorialComponent } from './page-tutorial/page-tutorial.component';
import { SportScrollbarComponent } from './sport-scrollbar/sport-scrollbar.component';
import { SubscribeClickPromptComponent } from './subscribe-click-prompt/subscribe-click-prompt.component';
import { SwcPreviewBannerComponent } from './swc-preview-banner/swc-preview-banner.component';
import { ExitPromptComponent } from './exit-prompt/exit-prompt.component';
import { SubFooterComponent } from './site-footer/sub-footer/sub-footer.component';
import { AboutDimersComponent } from './site-footer/about-dimers/about-dimers.component';
import { SportsbooksIconComponent } from './sportsbooks-icon/sportsbooks-icon.component';
import { BookOfferBannerComponent } from './book-offer-banner/book-offer-banner.component';
import { AusGambleRespComponent } from './aus-gamble-resp/aus-gamble-resp.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { RecaptchaFormsModule , RecaptchaModule } from 'ng-recaptcha';
import { TaboolaSectionComponent } from './taboola-section/taboola-section.component';




@NgModule({
  declarations: [
	ErrorPageComponent,
	SearchResultsPageComponent,
	SiteFooterComponent,
	SiteHeaderComponent,
	SportIconComponent,
	TeamIconComponent,
	FlagIconComponent,
	HubNavHeaderComponent,
  NextToGoComponent,
  RelatedArticlesRowComponent,
  ShortlistLoginPromptComponent,
  SportSignupBannerComponent,
  DesktopNavigationComponent,
  MobileNavigationComponent,
  BestOffersComponent,
  PageIntroCopyComponent,
  CopyBlockComponent,
  FaqContainerComponent,
  AdhesiveBannerComponent,
  SubscribeScrollPromptComponent,
  ArticleImageContainerComponent,
  DisplayAdComponent,
  MatchTileComponent,
  GameBarBlockComponent,
  MiniMatchTileComponent,
  SidebarBlockComponent,
  CallbackPageComponent,
  ProBannerComponent,
  QuickBetPromptComponent,
  PageTutorialComponent,
  SportScrollbarComponent,
  SubscribeClickPromptComponent,
  SwcPreviewBannerComponent,
  ExitPromptComponent,
  SubFooterComponent,
  AboutDimersComponent,
  SportsbooksIconComponent,
  BookOfferBannerComponent,
  AusGambleRespComponent,
  FeedbackFormComponent,
  TaboolaSectionComponent,
  ],
  imports: [
	CommonModule,
	FormsModule,
	FontAwesomeModule,
	RouterModule,
  MatchPipesModule,
  BookPipesModule,
  ReactiveFormsModule,
  RecaptchaModule,
  RecaptchaFormsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  exports: [
	ErrorPageComponent,
	SearchResultsPageComponent,
	SiteFooterComponent,
	SiteHeaderComponent,
	SportIconComponent,
	TeamIconComponent,
	FlagIconComponent,
  SportsbooksIconComponent,
  HubNavHeaderComponent,
  NextToGoComponent,
  RelatedArticlesRowComponent,
  ShortlistLoginPromptComponent,
  SportSignupBannerComponent,
  BestOffersComponent,
  PageIntroCopyComponent,
  CopyBlockComponent,
  FaqContainerComponent,
  AdhesiveBannerComponent,
  SubscribeScrollPromptComponent,
  ArticleImageContainerComponent,
  DisplayAdComponent,
  MatchTileComponent,
  MiniMatchTileComponent,
  SidebarBlockComponent,
  ProBannerComponent,
  QuickBetPromptComponent,
  PageTutorialComponent,
  SportScrollbarComponent,
  SubscribeClickPromptComponent,
  SwcPreviewBannerComponent,
  ExitPromptComponent,
  BookOfferBannerComponent,
  FeedbackFormComponent,
  TaboolaSectionComponent,
  ]
})
export class GeneralModule { }
