import { Inject, Injectable, Optional } from '@angular/core';
import { GoogleTagManagerConfig } from './google-tag-manager-config';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerShellService {

  constructor(
    @Optional()
    @Inject('googleTagManagerConfig')
    public config: GoogleTagManagerConfig = { id: null },
    @Optional() @Inject('googleTagManagerId') public googleTagManagerId: string,
    @Optional()
    @Inject('googleTagManagerAuth')
    public googleTagManagerAuth: string,
    @Optional()
    @Inject('googleTagManagerPreview')
    public googleTagManagerPreview: string
  ) {
    
  }

  public getDataLayer(): any[] {
    return [];
  }

  public addGtmToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  public pushTag(item: object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

}
