import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronRight, faEllipsisH, faSearch, faTimes, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { compareAsc, parseJSON } from 'date-fns';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogArticle } from 'src/app/blog-article.model';
import { BlogService } from 'src/app/blog.service';
import { Match } from 'src/app/match.model';
import { MatchService } from 'src/app/match.service';
import { SportDataService } from 'src/app/sport-data.service';
import { Sport } from 'src/app/sport.model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-desktop-navigation',
	templateUrl: './desktop-navigation.component.html',
	styleUrls: ['./desktop-navigation.component.scss']
})
export class DesktopNavigationComponent implements OnInit {
	
	sportsDropdownOpen = false;
	searchBarActive = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	dropdownHighlightedSportCode: string;
	accountDropdownOpen = false;
	searchText = "";
	featuredArticles: Record<string, Array<BlogArticle>> = {};
	upcomingMatches: Record<string, Array<Match>> = {};
	moreDropdownOpen = false;
	booksDropdownOpen = false;
    legalStatesDropdownOpen:boolean = false;
	bestBooksState: string = "";
	bestBooksURL: string = "/betting";
	bestBooksOptions: Array<{
		name: string;
		link: string;
		icon: string;
	}> = [];
	bestBooksLoading = true;
	bestBooksError = false;
	isBrowser: boolean;
	environment = environment;
	
	faChevronRight = faChevronRight;
	faChevronDown = faChevronDown;
	faEllipsisH = faEllipsisH;
	faSearch = faSearch;
	faTimes = faTimes;
	faExternalLinkAlt = faExternalLink;
	faCircleUser = faCircleUser
	states= 
    [   {name: 'Kentucky', iconLink: 'https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=25'}, 
        {name: 'Arizona', iconLink: 'https://cdn.cosmicjs.com/ec3f30c0-1001-11ee-b1c4-fdbd98303cc6-Arizona.svg'} ,{name: 'Colorado', iconLink: 'https://cdn.cosmicjs.com/ec401b20-1001-11ee-b1c4-fdbd98303cc6-Colorado.svg'} ,
    {name: 'Illinois', iconLink: 'https://cdn.cosmicjs.com/ec409050-1001-11ee-b1c4-fdbd98303cc6-Illinois.svg'} , {name: 'Indiana', iconLink: 'https://cdn.cosmicjs.com/ec428c20-1001-11ee-b1c4-fdbd98303cc6-Indiana.svg'},
    {name: 'Kansas', iconLink: 'https://cdn.cosmicjs.com/ec437680-1001-11ee-b1c4-fdbd98303cc6-Kansas.svg'}, {name: 'Louisiana', iconLink: 'https://cdn.cosmicjs.com/ec460e90-1001-11ee-b1c4-fdbd98303cc6-Louisiana.svg'} ,
    {name: 'Maryland', iconLink: 'https://cdn.cosmicjs.com/ec46d1e0-1001-11ee-b1c4-fdbd98303cc6-Maryland.svg'} ,{name: 'Massachusetts', iconLink: 'https://cdn.cosmicjs.com/ec5d1900-1001-11ee-b1c4-fdbd98303cc6-Massachusetts.svg'} , 
    {name: 'Michigan', iconLink: 'https://cdn.cosmicjs.com/ec5f3be0-1001-11ee-b1c4-fdbd98303cc6-Michigan.svg'},{name: 'New Jersey', iconLink: 'https://cdn.cosmicjs.com/ec5e2a70-1001-11ee-b1c4-fdbd98303cc6-New-Jersey.svg'}, 
    {name: 'New York', iconLink: 'https://cdn.cosmicjs.com/ec5fb110-1001-11ee-b1c4-fdbd98303cc6-New-York.svg'},{name: 'Ohio', iconLink: 'https://cdn.cosmicjs.com/ec602640-1001-11ee-b1c4-fdbd98303cc6-Ohio.svg'},
    {name: 'Pennsylvania', iconLink: 'https://cdn.cosmicjs.com/ec5e9fa0-1001-11ee-b1c4-fdbd98303cc6-Pennsylvania.svg'},{name: 'Iowa', iconLink: 'https://imgix.cosmicjs.com/d83e77a0-115c-11ee-abb6-f9903c690bdf-Iowa.svg'}];

   
	constructor(
		private router: Router,
		public authHelper: AuthHelperService,
		private blogService: BlogService,
		private sportDataService: SportDataService,
		public matchService: MatchService,
		@Inject(PLATFORM_ID) platformId: Object,
		@Inject(DOCUMENT) public document: Document,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}
	
	ngOnInit(): void {
		if (this.dropdownSports.length > 0) {
			this.dropdownHighlightedSportCode = this.dropdownSports[0].code;
		}


		if (this.isBrowser) {

			// this.sportDataService.getUpcomingMatches(this.dropdownSports.map(s => s.code), true)
			// 	.subscribe((matchArray: Array<Match>) => {
			// 		if (matchArray.length) {
			// 			this.dropdownSports.forEach(s => {
			// 				if (matchArray.some(m => m.MatchData.Sport === s.code && (!m.LiveData || m.LiveData.status !== "completed"))) {
			// 					this.upcomingMatches[s.code] = matchArray
			// 					.filter(m => m.MatchData.Sport === s.code && (!m.LiveData || m.LiveData.status !== "completed"))
			// 					.sort((a,b) => compareAsc(parseJSON(a.MatchData.Date), parseJSON(b.MatchData.Date)))
			// 					.slice(0,3)
			// 				}
			// 			});
			// 		}
			// 	});
			
			
			// this.dropdownSports.forEach(sport => {
			// 	this.blogService.getFeaturedArticleSummaries(1, sport.code)
			// 		.subscribe((articleArray: Array<BlogArticle>) => {
			// 			if (articleArray.length) {
			// 				this.featuredArticles[sport.code] = [articleArray[0]];
			// 			}
			// 		});
			// });

			// if (environment.optionalFeatures.includes("best-books")) {
			// 	this.blogService.userLocationData$
			// 		.subscribe(loc => {
			// 			if (loc && loc.iso_code === "US" && loc.state_name) {
			// 				this.bestBooksState = loc.state_name.toLowerCase();
				
			// 			} else {
			// 				// default to New Jersey if user location data can't be retrieved or user is outside of United States
			// 				this.bestBooksState = "new jersey";
			// 			}

			// 			this.bestBooksURL = `/betting/${this.bestBooksState.replace(/\s/g, "-")}`;

			// 			this.blogService.getBestBooksData(this.bestBooksState)
			// 			.subscribe(books => {
			// 				// console.log(books);

			// 				this.bestBooksOptions = books.map(b => ({
			// 					name: b.name,
			// 					link: `/betting/${this.bestBooksState.replace(" ", "-")}/${b.slug}`,
			// 					icon: b.small_logo?.url
			// 				}));

			// 				this.bestBooksLoading = false;
			// 		}, () => {
			// 			this.bestBooksError = true;
			// 		})
			// 		}, () => {
			// 			this.bestBooksError = true;
			// 		})
			// }

			
			
		}
	}
	
	toggleSportsDropdown(): void {
		this.sportsDropdownOpen = !this.sportsDropdownOpen;
	}
	
	showSportsDropdown(): void {
		this.sportsDropdownOpen = true;
	}
	
	hideSportsDropdown(): void {
		this.sportsDropdownOpen = false;
	}
	
	setDropdownHighlightedSport(sportCode: string): void {
		this.dropdownHighlightedSportCode = sportCode;
	}
	
	// dropdownHighlightedSport(): Sport {
	// 	if (this.dropdownSports.some(s => s.code === this.dropdownHighlightedSportCode)) {
	// 		return this.dropdownSports.find(s => s.code === this.dropdownHighlightedSportCode)
	// 	} else {
	// 		return null;
	// 	}
	// }
	
	closeSearch(): void {
		this.searchBarActive = false;
		this.searchText = "";
	}
	
	toggleAccountDropdown(): void {
		this.accountDropdownOpen = !this.accountDropdownOpen;
	}
	
	showAccountDropdown(): void {
		this.accountDropdownOpen = true;
	}
	
	hideAccountDropdown(): void {
		this.accountDropdownOpen = false;
	}

	visibleNavigable(sport: Sport): Array<any> {
		return sport.navigablePages.filter(p => !p.hideCondition);
	}
	
	advanceSearch(): void {
		if (!this.searchBarActive) {
			this.searchBarActive = true;
		} else if (this.searchText) {
			this.submitSearch();
		}
	}
	
	submitSearch(): void {
		this.router.navigateByUrl("/search?q=" + this.searchText)
	}
	
	toggleMoreDropdown(): void {
		this.moreDropdownOpen = !this.moreDropdownOpen;
	}
	
	showMoreDropdown(): void {
		this.moreDropdownOpen = true;
	}
	
	hideMoreDropdown(): void {
		this.moreDropdownOpen = false;
	}

	toggleLegalStatesDropdown(): void {
		this.legalStatesDropdownOpen = !this.legalStatesDropdownOpen;
	}

    showLegalStatesDropdown(): void {
		this.legalStatesDropdownOpen = true;
	}

    hideLegalStatesDropdown(): void {
		this.legalStatesDropdownOpen = false;
	}

    toggleBooksDropdown(): void {
		this.booksDropdownOpen = !this.booksDropdownOpen;
	}

	showBooksDropdown(): void {
		this.booksDropdownOpen = true;
	}
	
	hideBooksDropdown(): void {
		this.booksDropdownOpen = false;
	}
	
}