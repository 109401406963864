<div class="scroll-ctr">
	<div class="start-taper" *ngIf="!mobileLinksAtStart"></div>
	<div class="end-taper" *ngIf="!mobileLinksAtEnd"></div>
	<div class="sport-links mobile" #mobileLinkTrack (scroll)="checkScrollTapers()">
		<a *ngFor="let sport of sports" [routerLink]="visibleNavigable(sport)[0].path" [queryParams]="visibleNavigable(sport)[0].queryParams"
			draggable="false"
			class="sport-link">
			<app-sport-icon class="sport-icon" [code]="sport.code" colour="black"></app-sport-icon>
			<div class="sport-name">{{ sport.shortName }}</div>
		</a>
	</div>
</div>