<!-- <p>display-ad works!</p> -->
<!-- <div class="ad-placeholder">
	DISPLAY AD PLACEHOLDER
</div> -->
<!-- <a class="ad-ctr"
	href="http://wlbetamerica.adsrv.eacdn.com/C.ashx?btag=a_2559b_2321c_&affid=1997&siteid=2559&adid=2321&c="
	target="_blank"
	rel="noreferrer">
	<img class="ad-image large-only" loading="lazy"
		src="https://cdn.ciphersports.io/images/dimers-site/banner-ads/twinspires-desktop.gif"
		alt="$1000 Risk-Free Bet with TwinSpires Sports! Use Promo Code: BET1000. Click here to play now! New customers only. Void where prohibited. T&Cs apply.">
	<img class="ad-image small-only" loading="lazy"
		src="https://cdn.ciphersports.io/images/dimers-site/banner-ads/twinspires-mobile.gif"
		alt="$1000 Risk-Free Bet with TwinSpires Sports! Use Promo Code: BET1000. Click here to play now! New customers only. Void where prohibited. T&Cs apply.">
</a> -->

<ng-container *ngIf="environment.googleAdsConfig">
	<div [id]='adID' *ngIf="type === 'main-column'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'main-column2'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'main-column3'" class="google-ad main-column">
	</div>
	
	<div [id]='adID' *ngIf="type === 'sidebar-short'" class="google-ad sidebar-short">
	</div>
	
	<div [id]='adID' *ngIf="type === 'sidebar-multi'" class="google-ad sidebar-multi">
	</div>
	
	<div [id]='adID' *ngIf="type === 'header'" class="google-ad full-width">
	</div>
	
	<div [id]='adID' *ngIf="type === 'footer'" class="google-ad full-width">
	</div>
	
	<div [id]='adID' *ngIf="type === 'masthead'" class="google-ad masthead large-only">
	</div>
	
	<div [id]='adID' *ngIf="type === 'anchor'" class="google-ad anchor">
	</div>
	
	<div [id]='adID' *ngIf="type === 'home-POS1'" class="google-ad home-POS1">
	</div>
	
	<div [id]='adID' *ngIf="type === 'home-POS2'" class="google-ad home-POS2">
	</div>
	
	<div [id]='adID' *ngIf="type === 'home-POS3'" class="google-ad home-POS3 small-only">
	</div>
	
	<div [id]='adID' *ngIf="type === 'bestbets-POS1'" class="google-ad bestbets-POS1">
	</div>
	
	<div [id]='adID' *ngIf="type === 'bestbets-POS2'" class="google-ad bestbets-POS2">
	</div>
	
	<div [id]='adID' *ngIf="type === 'livenow-POS1'" class="google-ad livenow-POS1">
	</div>
	
	<div [id]='adID' *ngIf="type === 'livenow-POS2'" class="google-ad livenow-POS2 small-only">
	</div>
	
	<div [id]='adID' *ngIf="type === 'horseracing-POS1'" class="google-ad horseracing-POS1">
	</div>
	
	<div [id]='adID' *ngIf="type === 'horseracing-POS2'" class="google-ad horseracing-POS2">
	</div>
	
	<div [id]='adID' *ngIf="type === 'articles-POS1'" class="google-ad articles-POS1">
	</div>
	
	<div [id]='adID' *ngIf="type === 'articles-POS2'" class="google-ad articles-POS2">
	</div>
	
	<div [id]='adID' *ngIf="type === 'articles-POS3'" class="google-ad articles-POS3">
	</div>
	
	<div [id]='adID' *ngIf="type === 'articles-POS4'" class="google-ad articles-POS4">
	</div>
	
	<div [id]='adID' *ngIf="type === 'articles-POS5'" class="google-ad articles-POS5">
	</div>
</ng-container>

<ng-container *ngIf="environment.optionalFeatures.includes('placeholder-ads')">
	<img *ngIf="['header', 'footer'].includes(type)"
		class="ad-placeholder super-large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/draftkings-970x90.png"
		alt=""
	>
	<img *ngIf="['header', 'footer', 'masthead'].includes(type)"
		class="ad-placeholder regular-large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-728x90.gif"
		alt=""
	>
	<img *ngIf="['main-column', 'main-column2', 'main-column3', 'home-POS2', 'bestbets-POS1', 'bestbets-POS2',
		'livenow-POS1', 'articles-POS3', 'articles-POS4', 'articles-POS5'].includes(type)"
		class="ad-placeholder large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-728x90.gif"
		alt=""
	>
	<img *ngIf="['main-column', 'main-column2', 'main-column3', 'header', 'footer', 'anchor'].includes(type)"
		class="ad-placeholder small-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x50.gif"
		alt=""
	>
	<img *ngIf="['sidebar-short', 'home-POS1', 'horseracing-POS1', 'articles-POS1'].includes(type)"
		class="ad-placeholder"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x250.gif"
		alt=""
	>
	
	<img *ngIf="['home-POS2', 'home-POS3', 'bestbets-POS1', 'bestbets-POS2', 'livenow-POS1', 'livenow-POS2',
		'horseracing-POS2', 'articles-POS3', 'articles-POS4', 'articles-POS5'].includes(type)"
		class="ad-placeholder small-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x250.gif"
		alt=""
	>

	<img *ngIf="['sidebar-multi', 'articles-POS2'].includes(type)"
		class="ad-placeholder"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x600.png"
		alt=""
	>

	<img *ngIf="['horseracing-POS1'].includes(type)"
		class="ad-placeholder large-only"
		src="https://cdn.ciphersports.io/images/radar-sports/placeholder-banners/dk-bet5win150-nbaplayoffs-300x600.png"
		alt=""
	>
</ng-container>