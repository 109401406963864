<div class="nav-section">
    <div class="mobile-logo">
        <a routerLink="/">
            <img [alt]="environment.siteTitle" loading="lazy" [src]="environment.defaultLogo">
        </a>
    </div>
    <div class="right-side">
        <!-- <a routerLink="/dimers-pro" class="pro-button">
            <img
                class="pro-logo"
                src="https://cdn.ciphersports.io/images/dimers-site/dimers-pro-icon.svg"
                alt="Dimers Pro"
            >
        </a> -->
        <ng-container *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && authHelper.user">
            <div class="user-name">
                <div class="user-icon-ctr"
                    [class.active]="accountDropdownOpen"
                    (click)="toggleAccountDropdown()">
                    <img class="user-icon" [src]="authHelper.user.picture">
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && !authHelper.user">
            <div class="login-button" (click)="authHelper.goToLoginWithState()">Log In</div>
            <div class="signup-button" (click)="authHelper.goToLoginWithState(true)">Sign up</div>
        </ng-container>
        
        <div class="menu-button" (click)="toggleMobileNav()">
            <fa-icon [icon]="faBars"></fa-icon>
        </div>
    </div>
    <div class="mobile-navigation" [class.visible]="mobileNavVisible" [class.without-score-strip]="withoutScoreStrip">
        <div class="nav-item dropdown"
            *ngIf="dropdownSports.length > 0">
            <span>
                Sports
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
            <div class="slide-in-menu sport-menu show-on-hover" *ngIf="mobileNavVisible">
                <!-- <div class="back-button" (click)="hideSportsDropdown()">
                    <fa-icon class="left-arrow" [icon]="faChevronLeft"></fa-icon>
                </div> -->
                <div class="link-list">
                    <a class="link"
                        *ngFor="let sport of dropdownSports"
                        (click)="sendTapTag(sport.shortName)"
                        [routerLink]="visibleNavigable(sport)[0].path"
                        [queryParams]="visibleNavigable(sport)[0].queryParams">
                        <app-sport-icon [code]="sport.code" colour="white"></app-sport-icon>
                        <span>{{ sport.shortName }}</span>
                    </a>
                    <a class="link"
                        *ngFor="let sport of otherSports"
                        (click)="sendTapTag(sport.shortName)"
                        [routerLink]="visibleNavigable(sport)[0].path"
                        [queryParams]="visibleNavigable(sport)[0].queryParams">
                        <app-sport-icon [code]="sport.code" colour="white"></app-sport-icon>
                        <span>{{ sport.shortName }}</span>
                    </a>
                </div>
            </div>
        </div>
        <a routerLink="/best-bets" class="nav-item link" *ngIf="environment.availableSports.length > 0">Best Bets</a>
        <a routerLink="/live-now" class="nav-item link" *ngIf="environment.liveCoverageSports.length > 0">
            <span>
                Live Now
                <!-- <div class="live-beacon" *ngIf="this.liveLightVisible"></div> -->
            </span>
        </a>

        <a class="nav-item link" *ngIf="environment.optionalFeatures.includes('best-books')"
            routerLink="/betting"
        >
            <span>
                US Legal Betting
            </span>
            <!-- <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon> -->
            
        </a>

        <ng-container *ngFor="let link of environment.additionalNavOptions">
            <a *ngIf="!link.subLinks"
                [routerLink]="link.routerLink"
                [queryParams]="link.queryParams"
                class="nav-item link"
            >
                    {{ link.title }}
            </a>

            <div class="nav-item dropdown" *ngIf="link.subLinks">
                <span>
                    {{ link.title }}
                </span>
                <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
                <div class="slide-in-menu show-on-hover" *ngIf="mobileNavVisible">
                    <!-- <div class="back-button" (click)="hideMoreDropdown()">
                        <fa-icon class="left-arrow" [icon]="faChevronLeft"></fa-icon>
                    </div> -->
                    <div class="link-list">
                        <a class="link" *ngFor="let subLink of link.subLinks" [routerLink]="subLink.routerLink"
                            [queryParams]="subLink.queryParams"
                            (click)="sendTapTag(subLink.title)">
                            {{ subLink.title }}
                        </a>
                        
                    </div>
                </div>
            </div>

            
        </ng-container>


        <div class="nav-item dropdown">
            <span>
                More
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
            <div class="slide-in-menu about-menu show-on-hover" *ngIf="mobileNavVisible">
                <!-- <div class="back-button" (click)="hideMoreDropdown()">
                    <fa-icon class="left-arrow" [icon]="faChevronLeft"></fa-icon>
                </div> -->
                <div class="link-list">
                    <a class="link" routerLink="/parlay-picker"
                        *ngIf="environment.optionalFeatures.includes('parlay-picker')"
                        (click)="sendTapTag('Parlay Picker')">
                        Parlay Picker
                    </a>
                    <!-- <a class="link" routerLink="/free-to-play"
                        (click)="sendTapTag('Free to Play')">
                        Free to Play
                    </a> -->
                    <!-- <a class="link" routerLink="/live-now" *ngIf="environment.availableSports.length > 0"
                        (click)="sendTapTag('Live Now')">
                        Live Now
                    </a> -->
                    <a class="link" routerLink="/sports-betting-101"
                        *ngIf="environment.optionalFeatures.includes('sports-betting-101')"
                        (click)="sendTapTag('Betting Explained')">
                        Betting Explained
                    </a>
                    <!-- <a class="link" routerLink="/betting-calculator"
                    (click)="sendTapTag('Betting Calculator')">
                        Betting Calculator
                    </a> -->
                    <a class="link" routerLink="/podcasts" *ngIf="environment.optionalFeatures.includes('podcasts')"
                        (click)="sendTapTag('Podcasts')">
                        Podcasts
                    </a>
                    <!-- <a class="link" routerLink="/contributors"
                        (click)="">
                        Contributors
                    </a> -->
                    <!-- <a class="link" routerLink="/super-bowl-squares"
                        (click)="sendTapTag('Super Bowl Squares')">
                        Super Bowl Squares
                    </a> -->
                    <span class="link"
                        *ngIf="environment.optionalFeatures.includes('newsletter')"
                        (click)="sendTapTag('Newsletter'); fireNewsletterPopUp();">
                        Newsletter
                    </span>
                    <a class="link" routerLink="/news"
                        (click)="sendTapTag('News')">
                        {{ environment.siteTitle === 'Radar Sports' ? 'All Articles' : 'News' }}
                    </a>
                    <a class="link" routerLink="/news/authors"
                        (click)="sendTapTag('Contributors')">
                        Contributors
                    </a>
                    <a class="link" routerLink="/about-us"
                        (click)="sendTapTag('About Us')">
                        About Us
                    </a>
                    <a class="link" href="https://shop.dimers.com/" target="_href" linkrel="noreferrer" (click)="sendTapTag('Dimers Shop')" *ngIf="environment.optionalFeatures.includes('shop')">
                        Dimers Shop
                    </a>
                    <a class="link" routerLink="/responsible-gambling"
                        *ngIf="environment.optionalFeatures.includes('responsible-gambling-page')"
                        (click)="sendTapTag('Gamble Responsibly')">
                        Gamble Responsibly
                    </a>
                </div>
            </div>
        </div>

        <div class="nav-item" *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && !authHelper.user"
            (click)="authHelper.goToLoginWithState()">
            <span>
                Log In
            </span>

        </div>

        <!-- <div class="nav-item dropdown" *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && authHelper.user"
            [class.active]="accountDropdownOpen"
            (click)="toggleAccountDropdown()">
            <span>
                Hi {{ authHelper.user.user_metadata && authHelper.user.user_metadata.screenname ? authHelper.user.user_metadata.screenname : authHelper.user.name }}
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
            
        </div> -->
    
        <!-- <div class="mobile-search">
            <input type="text" [(ngModel)]="searchText" class="search-input"
                placeholder="Search..."
                (keyup.enter)="submitSearch()">
            <fa-icon [icon]="faSearch" (click)="submitSearch()"></fa-icon>
        </div> -->
    </div>

</div>
<div class="menu-backdrop" [class.disabled]="!mobileNavVisible" (click)="toggleMobileNav()"></div>

<!-- <div class="fixed-footer">
    <div class="footer-link" (click)="toggleSportsDropdown()">
        <img *ngIf="!sportsDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353c9e10-37fe-11ee-8ce3-27470be6bb93-Sports-OnDark.svg"
            height="1" width="1"
        >
        <img *ngIf="sportsDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb69a650-37fe-11ee-8ce3-27470be6bb93-Sports-Active.svg"
            height="1" width="1"
        >
        Sports
    </div>
    <div class="footer-link" routerLink="/best-bets" (click)="sendTapTag('Picks');hideAllDropdown()">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/356ada00-37fe-11ee-8ce3-27470be6bb93-QuickPicks-OnDark.svg"
            height="1" width="1"
        >
        Picks
    </div>
    <#-- <div class="footer-link" routerLink="/live-now" (click)="sendTapTag('Live Now');hideAllDropdown()">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://cdn.ciphersports.io/images/dimers-site/nav-icon/LiveNow_Yellow.svg"
            height="1" width="1"
        >
        Live Now
    </div> --#>
    <div class="footer-link" routerLink="/prop-bets" (click)="sendTapTag('Props');hideAllDropdown()" *ngIf="environment.optionalFeatures.includes('props')">
        <img class="footer-icon" alt="" loading="lazy"
            src="imgix.cosmicjs.com/353bdac0-37fe-11ee-8ce3-27470be6bb93-Props-OnDark.svg"
            height="1" width="1"
        >
        Props
    </div>
    <div class="footer-link" routerLink="/betting" (click)="sendTapTag('Promos');hideAllDropdown()" *ngIf="environment.optionalFeatures.includes('best-books')">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
            height="1" width="1"
        >
        Promos
    </div>
    <div class="footer-link" (click)="toggleMoreDropdown()">
        <img *ngIf="!moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353af060-37fe-11ee-8ce3-27470be6bb93-More-OnDark.svg"
            height="1" width="1"
        >
        <img *ngIf="moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb6894e0-37fe-11ee-8ce3-27470be6bb93-More-Active.svg"
            height="1" width="1"
    >
        More
    </div>
</div> -->
    


<div class="slide-in-menu about-menu" [class.offScreen]="!accountDropdownOpen">
    <!-- <div class="back-button" (click)="hideAccountDropdown()">
        <fa-icon class="left-arrow" [icon]="faChevronLeft"></fa-icon>
    </div> -->
    <div class="link-list">
        <ng-container *ngIf="environment.auth0Domain">
            <ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user; else loggedOut">
                <a class="link" (click)="hideAccountDropdown()" routerLink="/my-account">
                    My Account
                </a>
                <a class="link" (click)="authHelper.logout({ returnTo: document.location.origin })">
                    Log Out
                </a>
            </ng-container>
            <ng-template #loggedOut>
                <div class="link" (click)="authHelper.goToLoginWithState()">
                    Log In
                </div>
                <div class="link" (click)="authHelper.goToLoginWithState(true)">
                    Sign Up
                </div>
            </ng-template>
        </ng-container>
        
        <!-- <a class="link" (click)="hideAccountDropdown()" routerLink="/bet-center" *ngIf="environment.optionalFeatures.includes('bet-center')">
            Bet Center
        </a> -->

    </div>
</div>
