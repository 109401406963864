<nav>
	<app-site-header
		[fireNewsletterPopUp]="fireNewsletterPopUp"
		[showScore]="showScore"
	></app-site-header>
	<app-sport-scrollbar *ngIf="onHomePage()"></app-sport-scrollbar>
	<app-display-ad type="header" [permanent]="true" *ngIf="environment.googleAdsConfig?.adSpaces?.['header'] || environment.optionalFeatures.includes('placeholder-ads')" class="main-column-padding"></app-display-ad>
	<app-display-ad type="masthead" [permanent]="true" [largeOnly]="true" *ngIf="environment.googleAdsConfig?.adSpaces?.['masthead']" class="main-column-padding large-only"></app-display-ad>
</nav>
<main>
	<router-outlet></router-outlet>
</main>
<!-- <div class="update-prompt" [class.on-screen]="updatePromptVisible" (click)="reloadPage()">
	<div class="update-copy">
		New Data Available
	</div>
	<div class="update-button">
		<fa-icon [icon]="faRedoAlt" aria-hidden="true"></fa-icon>
		Reload
	</div>
</div> -->
<app-display-ad type="footer" [permanent]="true" *ngIf="environment.googleAdsConfig?.adSpaces?.['footer'] || environment.optionalFeatures.includes('placeholder-ads')" class="main-column-padding"></app-display-ad>
<app-site-footer
	[fireNewsletterPopUp]="fireNewsletterPopUp"
	[showPartner]="showPartner"
></app-site-footer>
<app-subscribe-click-prompt
	*ngIf="environment.optionalFeatures.includes('newsletter')"
	[visible]="newsletterPopUpVisible"
	[hideCallback]="hideNewsletterPopUp"
></app-subscribe-click-prompt>
<app-subscribe-scroll-prompt *ngIf="environment.optionalFeatures.includes('newsletter') && environment.newsletterProvider === 'surge'"></app-subscribe-scroll-prompt>
<app-exit-prompt *ngIf="environment.optionalFeatures.includes('newsletter') && environment.newsletterProvider === 'surge'"></app-exit-prompt>
<div class="anchor-ctr" *ngIf="environment.googleAdsConfig?.adSpaces?.['anchor'] || environment.optionalFeatures.includes('placeholder-ads')">
	<app-display-ad type="anchor" [smallOnly]="true" class="small-only" [permanent]="true"></app-display-ad>
</div>