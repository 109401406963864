import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule, HttpMethod } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AppModule,
		AuthModule.forRoot({
			domain: environment.auth0Domain,
			clientId: environment.auth0ClientId,
			authorizationParams: {
				audience: `https://${environment.auth0AudienceDomain}/api/v2/`,
				
				scope: "read:current_user",
			},
			
			httpInterceptor: {
				allowedList: [
					{
						uri: `https://${environment.auth0Domain}/api/v2/*`,
						httpMethod: HttpMethod.Get,
						tokenOptions: {
							authorizationParams: {
								audience: `https://${environment.auth0AudienceDomain}/api/v2/`,
								scope: "read:current_user"
							}
						}
					},
					{
						uri: `https://${environment.auth0Domain}/api/v2/users/*`,
						httpMethod: HttpMethod.Patch,
						tokenOptions: {
							authorizationParams: {
								audience: `https://${environment.auth0AudienceDomain}/api/v2/`,
								scope: "update:current_user_metadata"
							}
						}
					},
				]
			}
		}),
		NoopAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true
  }]
})
export class AppBrowserModule {}
