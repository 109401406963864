import Rollbar from 'rollbar/src/browser/core';
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x. 
// However, it will only work when setting either `allowSyntheticDefaultImports` 
// or `esModuleInterop` in your Typescript options.

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment } from '../environments/environment';

const rollbarConfig = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostBlockList: ["metabet.io", "subscribers.com", "lexer.io", "doubleclick.net", "sportsdata.io",
    "googletagmanager.com", "heapanalytics.com", "twitter.com", "chrome-extension:", "safari-extension:", "moz-extension:",
    "facebook.net", "embedly.com"],
  ignoredMessages: ["unknown", "^Script error.$"],

  payload: {
    client: {
      javascript: {
        source_map_enabled: true,

        code_version: "2.2.15",

        guess_uncaught_frames: true,
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
    console.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}