import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlogService } from './blog.service';
import { LocalisationService } from './localisation.service';
import { SPORTS } from './sports';

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard  {
  constructor(
    private blogService: BlogService,
    private router: Router,
    private localisationService: LocalisationService,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const pageSlug = route.url.join("/");
    console.log("pageSlug = " + pageSlug);
    // if the page code is actually a sport code, redirect to the default page for that sport
    if (this.localisationService.sportPrioritySort(SPORTS).some(s => s.code.toUpperCase() === pageSlug.toUpperCase())) {
      const sport = this.localisationService.sportPrioritySort(SPORTS).find(s => s.code.toUpperCase() === pageSlug.toUpperCase());
      const sportLinks = sport.navigablePages.filter(p => !p.hideCondition);
      this.router.navigate([sportLinks[0].path], {
        queryParams: sportLinks[0].queryParams
      });
      return of(false);
    }

    // if the first fragment of the page code is a sport code, it's a legacy news article route, so redirect to the correct one
    if (pageSlug.includes("/") && this.localisationService.sportPrioritySort(SPORTS).some(s => s.code.toUpperCase() === pageSlug.split("/")[0].toUpperCase())) {
      const sport = this.localisationService.sportPrioritySort(SPORTS).find(s => s.code.toUpperCase() === pageSlug.split("/")[0].toUpperCase());
      this.router.navigate(["news/" + pageSlug.split(/\//)[1]]);
      return of(false);
    }

    return this.blogService.builderPageExists(pageSlug)
      .pipe(
        map(result => {
          if (result) {
            return true;
          } else {
            this.router.navigate(["/"]);
            return false;
          }
        })
      )
  }
  
}
