<app-sub-footer *ngIf="environment.siteTitle === 'Dimers'"></app-sub-footer>
<app-about-dimers *ngIf="environment.siteTitle === 'Dimers'" [class.hidden-partner]="!this.showPartner"></app-about-dimers>
<div class="signup-footer" *ngIf="environment.optionalFeatures.includes('newsletter')">
	<div class="signup-footer-message">
		<h2 class="f-38 f-bold main-content-header">
			{{ environment.newsletterPromptTitle || ("Get the Latest From " + environment.siteTitle) }}
		</h2>
		<div class="f-16 main-content-header">
			{{ environment.newsletterPromptSubtitle || "Free picks, parlays and sportsbook offers sent to your inbox." }}
			
		</div>
	</div>
	<div
		*ngIf="environment.newsletterProvider === 'surge'"
		#formContainer
		class="signup-footer-desktop"
	></div>
	<div
		*ngIf="environment.newsletterProvider === 'surge'"
		#formContainerMobile
		class="signup-footer-mobile"
	></div>
	<div class="form-shell">
		<div
			class="form"
			*ngIf="!formSent && environment.newsletterProvider === 'placeholder'"
			ref="form"
		>
			<input
				type="email"
				[(ngModel)]="email"
				placeholder="Email"
				name="email"
				ngDefaultControl
				(keyup)="verifyForm()"
			>

			<div
				color="primary"
				class="submit-button"
				[class.button-enabled-yellow]="formValid && !formSending"
				[class.button-disabled-yellow]="!formValid || formSending"
				(click)="sendEmail()"
			>
				{{ formSending ? 'Please wait...' : 'Let\'s Go' }}
			</div>
		</div>
		<div class="send-info"
			*ngIf="formSent && !formError"
		>
			Thanks for joining. Stay tuned for more!
		</div>
		<div
			*ngIf="formError"
			class="send-info"
		>
			Sorry, something went wrong sending your details. Please try again.
		</div>

		<div class="form-disclaimer">
			<span *ngIf="environment.newsletterPromptTermsText" [innerHTML]="environment.newsletterPromptTermsText">

			</span>
			<span *ngIf="!environment.newsletterPromptTermsText">
				You can unsubscribe at any time. By signing up you agree to our
				<a href="/terms-of-service" target="_blank">Terms of Service</a> and
				<a href="/privacy-policy" target="_blank">Privacy Policy</a>.
			</span>
		</div>
	</div>
	
</div>
<div class="login-footer" *ngIf="environment.siteTitle === 'Stats Insider' && environment.auth0Domain && authHelper.userRetrievalDone && !authHelper.user">
	<img class="si-logo" loading="lazy" alt="Stats Insider"
		[src]="environment.defaultLogo">

	<div class="prompt-body">
		<div class="prompt-title">
			The must-have free account
		</div>
		<div class="prompt-subtitle">
			Get full access to our sports betting features when you sign up!
		</div>
		<div class="prompt-list">
			<div class="list-item">
				<div class="item-bullet"><fa-icon [icon]="faCheck"></fa-icon></div>
				<div class="item-text">Every play on the Best Bets</div>
			</div>
			<div class="list-item">
				<div class="item-bullet"><fa-icon [icon]="faCheck"></fa-icon></div>
				<div class="item-text">Watch list your favourite matches</div>
			</div>
		</div>
		<div class="login-row">
			<div class="signup-button" (click)="authHelper.goToLoginWithState()">
				Sign Up
			</div>
			<div class="login-button" (click)="authHelper.goToLoginWithState()">
				Login
			</div>
		</div>
	</div>

</div>
<div class="white-line"></div>
<div class="footer-content">
	<div class="footer-logo">
		<div class="client-logo">
			<div class="logo">
				<a routerLink="/" class="img-ctr">
					<img [alt]="environment.siteTitle" loading="lazy" [src]="environment.defaultLogo" [alt]="environment.siteTitle">
				</a>
			</div>
		</div>
		<div class="cipher-logo">
			<div  class="logo-header">
				{{ environment.siteTitle === 'Radar Sports' ? "Platform and Predictions Powered by" : "Proudly Part of" }}
			</div>
			<div class="logo">
				<a class="img-ctr" href="https://ciphersports.io/" target="_blank" rel="noreferrer">
					<img loading="lazy" alt="Cipher Sports Technology Group" src="https://cdn.ciphersports.io/assets/cipher-logo.svg">
				</a>
			</div>
		</div>
		<div class="sportradar-logo" *ngIf="environment.footerDataProviders?.length > 0">
			<div class="logo-header">
				Some data provided by		
			</div>
			<div class="logo-row">
				<div class="logo" *ngFor="let provider of environment.footerDataProviders">
					<img loading="lazy" [alt]="provider.name" [src]="provider.logoURL" />
				</div>
			</div>
			
		</div>
		<div class="social-links" *ngIf="environment.twitterUsername || environment.facebookURL || environment.instagramURL || environment.discordURL">
			<div class="social-links-header">
				Follow Us
			</div>
			<div class="social-link">
				<a *ngIf="environment.twitterUsername" href="https://twitter.com/{{ environment.twitterUsername }}" target="_blank" rel="noreferrer">
					<fa-icon [icon]="faTwitter" role="link" aria-label="Twitter"></fa-icon>
				</a>
				<a *ngIf="environment.facebookURL" [href]="environment.facebookURL" target="_blank" rel="noreferrer">
					<fa-icon [icon]="faFacebook" role="link" aria-label="Facebook"></fa-icon>
				</a>
				<a *ngIf="environment.instagramURL" [href]="environment.instagramURL" target="_blank" rel="noreferrer">
					<fa-icon [icon]="faInstagram" role="link" aria-label="Instagram"></fa-icon>
				</a>
				<a *ngIf="environment.discordURL" [href]="environment.discordURL" target="_blank" rel="noreferrer">
					<fa-icon [icon]="faDiscord" role="link" aria-label="Discord"></fa-icon>
				</a>
			</div>
		</div>
	</div>
	<div class="footer-links">
		<ng-container *ngFor="let link of environment.footerLinkColumnPages">
			<a *ngIf="link.strictHref"
				[href]="link.href"
			>
				{{ link.title }}
			</a>
			<a *ngIf="!link.strictHref"
				[routerLink]="link.href"
				[queryParams]="link.queryParams"
			>
				{{ link.title }}
			</a>
		</ng-container>
	</div>
	<div class="state-disclaimer" *ngIf="state === 'massachusetts'">
		<p>
			Must be 21+. Gambling problem? Call <a href="tel:+18003275050">1&#8209;800&#8209;327&#8209;5050</a> or visit <a href="https://helplinema.org" target="_blank">https://helplinema.org</a>.
		</p>

	</div>
	<div class="state-disclaimer" *ngIf="state === 'ohio'">
		<p>
			Must be in Ohio. 21+. Gambling Problem? Call <a href="tel:+18003272537">1&#8209;800&#8209;GAMBLER</a>.
		</p>

	</div>
	<app-aus-gamble-resp *ngIf="environment.siteTitle === 'Stats Insider'"></app-aus-gamble-resp>
	<div class="disclaimer" [innerHTML]="safeDisclaimer">
		
	</div>
</div>