import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookBackgroundColourPipe } from './book-background-colour.pipe';
import { BookForegroundColourPipe } from './book-foreground-colour.pipe';
import { BookLogoPipe } from './book-logo.pipe';
import { BookIconPipe } from './book-icon.pipe';
import { BookIconAltPipe } from './book-icon-alt.pipe';
import { BookLogoAltPipe } from './book-logo-alt.pipe';



@NgModule({
  declarations: [
    BookBackgroundColourPipe,
    BookForegroundColourPipe,
    BookLogoPipe,
    BookIconPipe,
    BookIconAltPipe,
    BookLogoAltPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BookBackgroundColourPipe,
    BookForegroundColourPipe,
    BookLogoPipe,
    BookIconPipe,
    BookIconAltPipe,
    BookLogoAltPipe
  ]
})
export class BookPipesModule { }
