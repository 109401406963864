import { Component } from '@angular/core';

@Component({
  selector: 'app-aus-gamble-resp',
  templateUrl: './aus-gamble-resp.component.html',
  styleUrls: ['./aus-gamble-resp.component.scss']
})
export class AusGambleRespComponent {

}
