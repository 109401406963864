import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sportsbooks-icon',
  templateUrl: './sportsbooks-icon.component.html',
  styleUrls: ['./sportsbooks-icon.component.scss']
})
export class SportsbooksIconComponent {
  @Input() sportBookCode: string;

}
