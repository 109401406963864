import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsPreMatchOnlyPipe } from './is-pre-match-only.pipe';
import { IsLivePipe } from './is-live.pipe';
import { IsOverPipe } from './is-over.pipe';
import { IsPausedPipe } from './is-paused.pipe';



@NgModule({
  declarations: [
    IsPreMatchOnlyPipe,
    IsLivePipe,
    IsOverPipe,
    IsPausedPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IsPreMatchOnlyPipe,
    IsLivePipe,
    IsOverPipe,
    IsPausedPipe,
  ]
})
export class MatchPipesModule { }
