import { ElementRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatchTeam } from "./match-team.model";
import { SPORTS } from "./sports";
// import { library, dom, IconDefinition } from '@fortawesome/fontawesome-svg-core';
// import { faPlus as faPlusLight, faMinus, faCheck as faCheckLight } from '@fortawesome/pro-light-svg-icons';
// import { faCheck as faCheckRegular, faAngleLeft, faAngleRight, faStar as faStarRegular, faPlus as faPlusRegular, faSearch,
// 	faCircle as faCircleRegular, faTimes, faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
// import { faChevronUp, faChevronDown, faCalendarAlt, faTimesCircle, faChevronLeft, faChevronRight, faCaretUp, faCaretDown,
// 	faCircle as faCircleSolid, faCaretLeft, faCaretRight, faSortUp, faSortDown, faSort, faStar as faStarSolid,
// 	faRedoAlt } from '@fortawesome/pro-solid-svg-icons';
// import { faTwitter } from '@fortawesome/free-brands-svg-icons';

@Injectable({
	providedIn: "root"
})
export class GeneralService {

	constructor(
		private router: Router
	) {}

	isSoccer(sport: string): boolean {
		const matchingSport = SPORTS.find(s => s.code === sport.toUpperCase());
		return matchingSport?.isSoccer;
	}



	getMatchLink(matchid: string): string {
		if (!matchid) return "";
        var segments = matchid.toLowerCase().split(/_(.+)/);
        return `/sport-hub/${segments[0]}/schedule/${segments[1]}`;
	}

	getFuturesLink(sport: string): string {
		return `/futures/${sport}`;
	}

	getFixtureLink(sport: string): string {
		if (!sport) return "";
        return "/sport-hub/" + sport.toLowerCase() + "/schedule";
	}

	getValueBetsLink(): string {
		return "/best-bets";
	}

	shortenName(firstName: string, surname: string): string {
		return firstName.split(" ").map(s => s[0] + ". ").join("") + surname;
	}

	getShortName(sport: string): string {
		if (SPORTS.some(s => s.code.toLowerCase() === sport.toLowerCase())) {
			return SPORTS.find(s => s.code.toLowerCase() === sport.toLowerCase()).shortName;
		}

		return sport;
	}

	initialiseBlock(el: ElementRef): void {
		// Font Awesome icons that are used in blocks
		// library.add(faPlusLight, faMinus, faCheckLight, faCheckRegular, faAngleLeft, faAngleRight, faStarRegular,
		// 	faPlusRegular, faSearch, faCircleRegular, faTimes, faChevronUp, faChevronDown,
		// 	faCalendarAlt, faTimesCircle, faChevronLeft, faChevronRight, faCaretUp,
		// 	faCaretDown, faCircleSolid, faCaretLeft, faCaretRight, faSortUp, faSortDown, faSort, faStarSolid,
		// 	faAngleUp, faAngleDown, faRedoAlt, faTwitter as IconDefinition);

		// dom.watch();

		const element = el.nativeElement;
		if (element) {
			element.addEventListener("click", (event: Event) => {
				const linkParent = (event.target as Element).closest("a[href]:not([override-callback])");
				if (linkParent !== null
					&& linkParent.getAttribute("target") !== "_blank"
					&& (event as MouseEvent).button === 0 && !((event as MouseEvent).shiftKey
						|| (event as MouseEvent).altKey || (event as MouseEvent).metaKey || (event as MouseEvent).ctrlKey)) {
					event.preventDefault();
					this.router.navigateByUrl(linkParent.getAttribute("href"));
				}
			});
		}
	}

	hedgedProbString(probNumber: number, decPlaces: number): string {
		// if passed in probability is not a number, return a dash
		if (typeof probNumber !== "number") {
			return "-";
		}

		// if number would get rounded to 100%, but isn't, change to "more than" highest visible number below 100%
		if (parseFloat((probNumber * 100).toFixed(decPlaces)) === 100 && probNumber !== 1) {
			return `>99${decPlaces === 0 ? "" : "."}${"9".repeat(decPlaces)}%`;
		}

		// if number would get rounded to 0%, but isn't, change to "more than" lowest visible number above 0%
		if (parseFloat((probNumber * 100).toFixed(decPlaces)) === 0 && probNumber !== 0) {
			return `<${decPlaces === 0 ? "1" : ("0." + "0".repeat(decPlaces - 1) + "1")}%`;
		}

		// otherwise, return the number converted to a percentage, to the specified number of decimal places
		return (probNumber * 100).toFixed(decPlaces) + "%";
	}

	teamNameDisplay(teamObject: MatchTeam): string {
		if (teamObject) {

			if (teamObject.Nickname) {
				return teamObject.Nickname;
			}
			if (teamObject.DisplayName) {
				return teamObject.DisplayName;
			}
			return "";
		}

		return "";
	}
}
