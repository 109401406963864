import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalisationService } from './localisation.service';
import { SPORTS } from './sports';

@Injectable({
  providedIn: 'root'
})
export class ValidSportGuard  {
  constructor(
    private router: Router,
    private localisationService: LocalisationService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const sportCode = route.paramMap.get("sport");
    if (this.localisationService.sportPrioritySort(SPORTS).some(s => s.code.toLowerCase() === sportCode.toLowerCase())) {
      return true;
    }
    return this.router.parseUrl("/notfound");
  }
  
}
