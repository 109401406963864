import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MetaSetterService } from '../meta-setter.service';

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

	constructor(private metaSetter: MetaSetterService, private swUpdate: SwUpdate) { }

	ngOnInit(): void {
		this.metaSetter.setMeta({
			title: environment.siteTitle,
			description: environment.defaultMetaDescription,
			metaImageURL: environment.defaultMetaImage,
		})

		if (this.swUpdate.isEnabled && typeof window !== "undefined") {
			this.swUpdate.versionUpdates
				.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
				)
				.subscribe(() => {
					window.location.reload();
				});
		}
	}

}
