import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nth'
})
export class NthPipe implements PipeTransform {

  transform(n: number): string {
		if (n > 3 && n < 21) {return `${n}th`; } // thanks kennebec
		switch (n % 10) {
		case 1:
			return `${n}st`;
		case 2:
			return `${n}nd`;
		case 3:
			return `${n}rd`;
		default:
			return `${n}th`;
		}
	}

}
