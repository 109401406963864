import { AfterViewInit, Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { CookieService } from "ngx-cookie";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exit-prompt',
  templateUrl: './exit-prompt.component.html',
  styleUrls: ['./exit-prompt.component.scss']
})
export class ExitPromptComponent implements AfterViewInit, OnDestroy {
  
  user: any = null;
  userRetrieved = false;
  userRetrievalDone = false;

  @ViewChild("formContainer") containerElement: ElementRef;

  constructor(
    private cookieService: CookieService,
    private auth: AuthService,
  ) { }

  ngAfterViewInit(): void {
    if (typeof window !== "undefined" && environment.newsletterProvider === "surge") {
      if (environment.auth0Domain) {
        this.auth.user$
          .subscribe((user) => {
            this.userRetrievalDone = false;
            if (user && user.sub && !this.userRetrieved) {
              this.user = user;
            } else if (!this.userRetrieved) {
              this.decideToShowPopup();
            }

            this.userRetrieved = true;
            this.userRetrievalDone = true;
          });
      } else {
        this.decideToShowPopup();
      }
      
    }
    
	}

  decideToShowPopup(): void {
    if (!(this.cookieService.hasKey("mtc_id") || (this.cookieService.hasKey("neverShowPopup") && this.cookieService.get("neverShowPopup") === "true"))) {
      if (Array.from(this.containerElement.nativeElement.children).some(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/focus/2.js`)) {
        const oldScript: HTMLScriptElement = Array.from(this.containerElement.nativeElement.children).find(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/focus/2.js`) as HTMLScriptElement;
        oldScript.remove();
      }
        
      let js;
      js = document.createElement("script");
      js.src = `https://ams.ciphersports.io/focus/2.js`;
      (this.containerElement.nativeElement as HTMLElement).appendChild(js);

    }
  }

  ngOnDestroy(): void {
    if (Array.from(this.containerElement.nativeElement.children).some(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/focus/2.js`)) {
      const oldScript: HTMLScriptElement = Array.from(this.containerElement.nativeElement.children).find(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/focus/2.js`) as HTMLScriptElement;
      oldScript.remove();
    }
  }

}
