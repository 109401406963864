<div class="about-dimers">
  <div class="heading">
    About Dimers
  </div>
  <div class="statistics">
    <div class="event-number">
      <div class="number">
        <img src="https://cdn.ciphersports.io/images/dimers-site/about-dimers-icon-1.svg" height="40" width="40" loading="lazy" alt="icon"> 22,000+
      </div>
      <div class="text">
        Events covered in 2022
      </div>
    </div>
    <div class="social-media-number">
      <div class="number">
        <img src="https://cdn.ciphersports.io/images/dimers-site/about-dimers-icon-2.svg" height="40" width="40"loading="lazy" alt="icon"> 40,000+
      </div>
      <div class="text">
        Social media community
      </div>
    </div>
    <div class="user-number">
      <div class="number">
        <img src="https://cdn.ciphersports.io/images/dimers-site/about-dimers-icon-3.svg" height="40" width="40" loading="lazy" alt="icon"> 2,700,000+
      </div>
      <div class="text">
        Users
      </div>
    </div>
  </div>
  <div class="partners">
    <div class="heading">
      Visit our partners
    </div>
    <div class="partner-logos">
      <ng-container *ngIf="!offersLoading && !offersError && books">
        <swiper-container *ngIf="browserMode" slides-per-view="auto" space-between="50" class="mySwiper">
          <swiper-slide *ngFor="let book of books" class="">
            <a 
            attr.data-recording-click-props='{
              "offer-bookmaker": "{{ book.slug }}",
              "offer-bookmaker-id": "{{ book.id }}",
              "offer-component": "visit-our-partners",
              "offer-state-code": "{{ this.stateCode}}"
              }'
            (click)="redirectBookieLink(book.website_url,
              {src: 'reviews', promo_id: book.id},
              book
            )">
              <source [srcset]="book.welcome_promo_logo?.dynamic_url + '?w=200&fm=webp'" type="image/webp">
              <source [srcset]="book.welcome_promo_logo?.dynamic_url + '?w=200'"
                [type]="book.welcome_promo_logo?.mime_type">
              <img width="100%" height="32" [alt]="book.welcome_promo_logo?.custom_properties.alt_text"
                [src]="book.welcome_promo_logo?.dynamic_url + '?w=200'" loading="lazy">
            </a>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>
  </div>
</div>