import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { BookOffer } from 'src/app/book-offer.model';
import { UserLocationData } from 'src/app/user-location-data.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-dimers',
  templateUrl: './about-dimers.component.html',
  styleUrls: ['./about-dimers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutDimersComponent implements OnInit {

  offersLoading = true;
  offersError = false;
  locationData: UserLocationData;
  books: Array<Record<string, any>> = [];
  stateCode:string = "";
  browserMode = false;

  constructor(
    private blogService: BlogService,
		public authHelper: AuthHelperService,
		private router: Router,
		private gtmService: GoogleTagManagerService,
		@Inject(PLATFORM_ID) platformId: object,
  ) {
	this.browserMode = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.loadOffers();
  }

  loadOffers() {
		this.offersLoading = true;
		this.offersError = false;

		// bookmaker data is location-sensitive, so don't begin loading process if on server-side rendering
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			this.blogService.userLocationData$
				.subscribe(loc => {
					let stateCode;
					this.locationData = loc;
					if (loc && loc.iso_code === "US" && loc.state_name) {
						stateCode = loc.state_name.toLowerCase();
			
					} else {
						// default to New Jersey if user location data can't be retrieved or user is outside of United States
						stateCode = "new jersey";
					}
					//passing the stateCode to smartlook property
					this.stateCode = stateCode.replace(/ /g, '-');
					this.blogService.getBestBooksData(stateCode)
					.subscribe(books => {
						// console.log(books);

						this.books = books;

						this.offersLoading = false;
				}, () => {
					this.offersError = true;
				})
				})
		}
		
	}

	

	redirectBookieLink(url: string, queryParams: Record<string, string>, bookmaker) {
		queryParams.c = this.excludeBookies(bookmaker?.slug || "") ? undefined : ((this.router.url.split("/")[1] || 'home')
		  + ((environment.auth0Domain && this.authHelper.user) ? ('|' + this.authHelper.user.id) : '|visitor'));
	
		const combinedUrl = url + "?" + new URLSearchParams(queryParams).toString();
	
			if (typeof window !== "undefined") {
				const gtmTag = {
					event: "exit",
					event_category: "reviews",
					event_label: bookmaker.slug,
				};
		
				this.gtmService.pushTag(gtmTag)
					.then(() => {
						window.open(combinedUrl, "_blank");
					})
					.catch(e => {
						console.error("Google Tag Manager Script failed: " + e)
						window.open(combinedUrl, "_blank");
					});
			}
	  }

  redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bestOffer: BookOffer) {
		if (!this.excludeBookies(bestOffer?.bookmaker?.slug || "")) {
			queryParams.c = (this.router.url.split("/")[1] || 'home') + (
				(environment.auth0Domain && this.authHelper.user)
				? ('|' + this.authHelper.user.id)
				: '|visitor'
			);
		}

		const combinedUrl = url + "?" + new URLSearchParams(queryParams).toString();

		if (typeof window !== "undefined") {
			const gtmTag = {
				event: "exit",
				event_category: "welcome",
				event_label: bestOffer.bookmaker.slug,
			};
	
			this.gtmService.pushTag(gtmTag)
				.then(() => {
					window.open(combinedUrl, "_blank");
				})
				.catch(e => {
					console.error("Google Tag Manager Script failed: " + e)
					window.open(combinedUrl, "_blank");
				});
		}
	}

  excludeBookies(bookie: string) {
		bookie = bookie.replace(/\s/g, '').toLowerCase()
		switch (bookie) {
			case 'bet365':
			case 'bet_365':
			return true
			case 'pointsbet':
			return true
			case 'betmgm':
			return true
			default:
			return false
		}
	}
}
