import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { BlogArticle } from './blog-article.model';
import { BlogAuthor } from './blog-author.model';
import { BookOffer } from './book-offer.model';
import { compareDesc, parseJSON } from 'date-fns';
import { SPORTS } from './sports';
import { LocalisationService } from './localisation.service';
import { UserLocationData } from "./user-location-data.model";
import { environment } from 'src/environments/environment';
import { HoneycombService } from './honeycomb.service';
import { LandingPage } from './landing-page.model';
import { isPlatformBrowser } from '@angular/common';


// const api = require("@opentelemetry/api");
// const tracer = require("tracing.ts");

@Injectable({
	providedIn: 'root'
})
export class BlogService {
    
    cosmmicApi = "https://api.cosmicjs.com/v2/buckets/";
	cosmicBucketSlug = 'insiderapi';
	cosmicReadKey ="5px9a31VWUleoToX0AH7P0W3DasfsBBNO4qgYoblJCwNlbiSYI";

	adhesiveBannersVisible = true;
    browserMode:boolean = false;
	userLocationData$: Observable<UserLocationData> = this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
		.pipe(
			map((response: any) => {
				if (response) {
					return (response as UserLocationData);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			shareReplay(1),
			catchError(this.handleError<UserLocationData>())
		);

	constructor(
		private http: HttpClient,
		private localisationService: LocalisationService,
		private honeycomb: HoneycombService,
        @Inject(PLATFORM_ID) platformId: string,
	) { 
        this.browserMode = isPlatformBrowser(platformId);
	}

	disableAdhesiveBanners(): void {
		this.adhesiveBannersVisible = false;
	}

	getArticle(slug: string, revisionID?: string): Observable<BlogArticle> {
		console.log('getArticle');
		let rno = Math.random()
		console.time('getArticle' + rno)
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/articles/${slug}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					console.timeLog('getArticle' + rno)
					return response.data;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogArticle>())
		)
	}

	// TODO
	// getLatestFeaturedGame(): Observable<String> {
	// 	return of("");
	// }

	getLatestArticleSummaries(count: number): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummaries');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/articles?sort=-is_prioritised,-published_date&per_page=${count}&articleCategory=dimers_content,api_ingested`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					return response.data;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getBuilderPage(pageCode: string): Observable<LandingPage> {
		console.log('getBuilderPage');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/page-builders?filter[path_url]=/${pageCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return response.data[0]
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<LandingPage>())
		)
	}

	builderPageExists(pageCode: string): Observable<boolean> {
		console.log('builderPageExists');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/page-builders?filter[path_url]=/${pageCode}`)
		.pipe(
			map((response: any) => {
				return response.data && response.data.length > 0;
			}),
			catchError(() => {
				return of(false)
			})
		)
	}

	// TODO
	// getLatestArticleSummariesByTag(tag: string, count: number): Observable<Array<BlogArticle>> {
	// 	return of([]);
	// }

    //excludeFeature parameter only used for SI(for horse radcing home page use only)
	getLatestArticleSummariesByCategory(category: string, count: number, exceptID?: number, excludeFeature?: boolean): Observable<Array<BlogArticle>> {
		console.log('getLatestArticleSummariesByCategory');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/related-articles?sort=-prioritise_until,-published_date`
			+ `&per_page=${count}&sport_league=${category}&articleCategory=dimers_content,api_ingested`
			+ (exceptID ? `&except[0]=${exceptID}` : ""))
		.pipe(
			map((response: any) => {
				if (response.data) {
					// return (response.data as Array<BlogArticle>)
					// 	.sort((a,b) => compareDesc(
					// 		parseJSON(a.published_date),
					// 		parseJSON(b.published_date)
					// 	));
					return response.data;
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getFeaturedArticleSummaries(count: number, category?: string): Observable<Array<BlogArticle>> {
		// console.log('getFeaturedArticleSummaries');
		let rno = Math.random()
		// console.time('getFeaturedArticleSummaries' + rno)
		const baseUrl = category ? `${environment.dimersApiDomain}/api/v1/featured-league-articles?sport_league=${category}&` : `${environment.dimersApiDomain}/api/v1/featured-articles?`
		this.honeycomb.sendMessage({
			message: `hitting ${baseUrl}limit=${count}&sort=order_index`
		});
		console.log(this.http.get<any>(`${baseUrl}limit=${count}&sort=order_index`))
		return this.http.get<any>(`${baseUrl}limit=${count}&sort=order_index`)
		
		.pipe(
			map((response: any) => {
				// console.log(response)

				// let span = tracer.startSpan("expensive-query");

				if (response.data) {
					// console.timeLog('getFeaturedArticleSummaries'+rno)
					return (response.data as Array<BlogArticle>)
						.sort((a,b) => a.featured_article?.order_index - b.featured_article?.order_index);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	getAuthors(): Observable<Array<BlogAuthor>> {
		console.log('getAuthors');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/authors?limit=all`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return response.data.map(o => ({
						...o,
						id: o.id.toString(),
						featured_candidate: true,
					}));
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogAuthor>>())
		)
	}

	getAuthor(slug: string): Observable<BlogAuthor> {
		console.log('getAuthor');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/authors/${slug}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return response.data.map(o => ({
						...o,
						id: o.id.toString(),
					}));
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogAuthor>())
		)
	}

	getArticleSummariesPage(authorID: string, category: string, query: string, skip: number = 0, count: number): Observable<{articles: Array<BlogArticle>, totalCount: number}> {
		console.log('getArticleSummariesPage');
		let baseUrl: string;
		if (authorID) {
			baseUrl = `${environment.dimersApiDomain}/api/v1/authors/${authorID}/articles?`;
		} else {
			baseUrl = `${environment.dimersApiDomain}/api/v1/articles?`;
		}
		return this.http.get<any>(`${baseUrl}sort=-is_prioritised,-published_date&withFeatured=true&articleCategory=dimers_content,api_ingested`
			+ (category ? `&filter[tagContaining]=${category}` : "")
			+ (authorID ? `&metadata[author]=${authorID}` : "")
			+ (query ? `&filter[search]=${query}` : "")
			+ `&page=${Math.floor(skip / count) + 1}&limit=${count}`, 
		)
		.pipe(
			map((response: any) => {
				if (response && response.success && response.data && response.meta.total) {
					return {articles: (response.data as Array<BlogArticle>), totalCount: (response.meta.total as number)};
				} else if (response && (response.data === null || !response.meta.total)) {
					return {articles: [], totalCount: 0};
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<{articles: Array<BlogArticle>, totalCount: number}>())
		)
	}

	getAuthorSummariesPage(authorSlug: string, skip: number, count: number) {
		return of([]);
	}

	getAdhesiveBanner(stateCode: string): Observable<Record<string, any>> {
		console.log('getAdhesiveBanner');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/banners?state=${stateCode}`)
		.pipe(
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	// getLatestArticleCategories(): Observable<Array<string>> {
	// 	return this.http.get<any>(https://api.cosmicjs.com/v1/insiderapi/objects?type=stats-insider-articles&sort=originally_published&limit=1&props=metafields")
	// 	.pipe(
	// 		map((response: any) => {
	// 			if (response && response.objects && response.objects[0]
	// 				&& response.objects[0].metafields && response.objects[0].metafields.some(m => m.key === "categories")) {
	// 				return response.objects[0].metafields.find(m => m.key === "categories").options.map(o => o.value);
	// 			} else if (response.errors) {
	// 				throw new Error(response.errors[0].message)
	// 			} else {
	// 				throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
	// 			}
	// 		}),
	// 		catchError(this.handleError<Array<Record<string, any>>>())
	// 	)
	// }

	getWelcomeOffers(state: string, count?: number, bookmakerID?: number): Observable<Array<BookOffer>> {
		console.log('getWelcomeOffers');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/welcome-promo?${count ? `limit=${count}&` : ``}state=${state}${bookmakerID? `&filter[onlySportsBook]=${bookmakerID}` : ""}`)
			.pipe(
				map((response: any) => {
					if (response.data) {
						return (response.data as Array<BookOffer>);
					} else if (response.errors) {
						throw new Error(response.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<Array<BookOffer>>())
			)
	}

	getBestOffers(count: number, state: string, sportCode?: string): Observable<Array<BookOffer>> {
		console.log('getBestOffers');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/best-promo?limit=${count}&state=${state}${
			sportCode ? `&sport_league=${sportCode.toUpperCase()
		}` : ""}`)
			.pipe(
				map((response: any) => {
					if (response.data) {
						return (response.data as Array<BookOffer>);
					} else if (response.errors) {
						throw new Error(response.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<Array<BookOffer>>())
			)
	}

	getUserLocationData(): Observable<UserLocationData> {
		console.log('getting geo data getUserLocationData()');
		return this.http.get<any>(`${environment.dimersGeoDomain}/v1/get_geolocation`)
			.pipe(
				map((response: any) => {
					if (response.data) {
						return (response.data as UserLocationData);
					} else if (response.errors) {
						throw new Error(response.errors[0].message)
					} else {
						throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
					}
				}),
				catchError(this.handleError<UserLocationData>())
			)
	}

	getAppSettings(pageCode: string): Observable<Record<string, any>> {
		let rno = Math.random()
		console.log('getAppSettings');
		console.time('getAppSettings' + rno);
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/page-settings?page=1&limit=5&filter[app_page]=${pageCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {

				console.timeLog('getAppSettings' + rno) 
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBetHubSettings(sportCode: string): Observable<Record<string, any>> {
		console.log('getBetHubSettings');
		let rno = Math.random()
		console.time('getBetHubSettings' + rno)
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bethub-settings?page=1&limit=5&sort=-created_at&filter[search]=${sportCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					console.timeLog('getBetHubSettings' + rno)
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getMatchMeta(matchID: string): Observable<Record<string, any>> {
		console.log('getMatchMeta for match ' + matchID);
		let rno = Math.random()
		console.time('getMatchMeta' + rno)
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bethub-matches/generate-seo?match_id=${matchID}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					console.timeLog('getMatchMeta' + rno)
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBestOddsMeta(): Observable<Array<Record<string, any>>> {
		console.log('getBestOddsMeta');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-best-odds?limit=all`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getNewsMeta(sportCode: string): Observable<Record<string, any>> {
		console.log('getNewsMeta');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/tags?page=1&limit=20&filter[tagContaining]=${sportCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getFuturesMeta(sportCode: string): Observable<Record<string, any>> {
		console.log('getFuturesMeta');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-futures?filter[search]=${sportCode}`)
		.pipe(
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}
	// this is for sport-specific version of best bets page 
	getSportBestBetsMeta(sportCode: string): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-best-bets?filter[search]=${sportCode}`)
		.pipe(
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	// this is for sport-specific version of best props page 
	getSportBestPropsMeta(sportCode: string): Observable<Record<string, any>> {
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-best-props?filter[search]=${sportCode}`)
		.pipe(
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}



	getStateSettings(stateCode?: string): Observable<Array<Record<string, any>>> {
		console.log('getStateSettings');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/state-settings?${stateCode ? `filter[onlyState]=${stateCode}` : `limit=all&sort=custom_order_index`}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBestBooksData(stateCode?: string): Observable<Array<Record<string, any>>> {
		console.log('getBestBooksData');
		return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/location-order?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBookmakerData(bookmakerCode: string, stateCode?: string): Observable<Record<string, any>> {
		console.log('getBookmakerData');
		return this.http.get<any>(stateCode ? `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}?state=${stateCode}` : `${environment.dimersApiDomain}/api/v1/bookmakers/${bookmakerCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getBookmakerPromo(id: string): Observable<Record<string, any>> {
		console.log('getBookmakerPromo');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/promo?filter[id]=${id}`)
		.pipe(
			map((response: any) => {
				if (response.data && response.data.length > 0) {
					return (response.data[0]);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getHyperlink(id: string): Observable<Record<string, any>> {
		console.log('getHyperlink');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/hyperlinks/${id}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getPodcastInformation(): Observable<Record<string, any>> {
		console.log('getPodcastInformation');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-podcasts`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getPodcastList(): Observable<Array<Record<string, any>>> {
		console.log('getPodcastList')
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/app-podcasts/podcast?sort=custom_order_index`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	

	getFreeToPlayData(): Observable<Record<string, any>> {
		console.log('getFreeToPlayData');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/free-to-play`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	addNewsletterEmail(email: string, firstName?: string): Observable<boolean> {
		console.log('getting geo data addNewsletterEmail()');
		return this.http.post(`if this actually sends somewhere i'll be really fucken impressed`, {
			email: email,
			first_name: firstName,
		})
		.pipe(
			map((response: any) => {
				if (response.success) {
					return true;
				} else if (response.errors) {	
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<boolean>())
		)
	}

	getBettingExplainedCategories(): Observable<Array<Record<string, any>>> {
		console.log('getBettingExplainedCategories()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/category`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<Record<string, any>>>())
		)
	}

	getBettingExplainedCategory(slug: string): Observable<Record<string, any>> {
		console.log('getBettingExplainedCategory()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/category/${slug}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)

	}

	getMatchPagePromos(matchID: string, stateCode: string): Observable<Array<Record<string, any>>> {
		console.log('getMatchPagePromos()');
		if (!matchID.includes("_")) {
			return of([]);
		}
		console.log('getting geo data getUserLocationData()');
		matchID = matchID.toLowerCase();
		const sportCode = matchID.split("_")[0];
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers/bespoke-promo?filter[match_id]=${matchID}&filter[tagContaining]=${sportCode}&state=${stateCode}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<Record<string, any>>>())
		)
	}

	getBettingExplainedArticle(slug: string): Observable<BlogArticle> {
		console.log('getBettingExplainedArticle()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings/${slug}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<BlogArticle>())
		)

	}

	getOnboardingBookmakers(): Observable<Array<Record<string, any>>> {
		console.log('getOnboardingBookmakers()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/bookmakers?filter[is_onboarding]=true&sort=onboarding_sort_index&limit=all`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<Record<string, any>>>())
		)
	}

	searchBettingExplainedArticles(searchTerm: string): Observable<Array<BlogArticle>> {
		console.log('searchBettingExplainedArticles()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/sport-bettings?filter[search]=${searchTerm}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Array<BlogArticle>>())
		)
	}

	sessionIncrementer = 1;

	getIncrement(): number {
		console.log('getIncrement()');
		this.sessionIncrementer++;
		return this.sessionIncrementer;
	}

	getSportsBettingPoll(id: string): Observable<Record<string, any>> {
		console.log('getSportsBettingPoll()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/poll/${id}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getResponsiveBanner(id: number): Observable<Record<string, any>> {
		console.log('getResponsiveBanner()');
		return this.http.get<any>(`${environment.dimersApiDomain}/api/v1/responsive-banners/${id}`)
		.pipe(
			map((response: any) => {
				if (response.data) {
					return (response.data);
				} else if (response.errors) {
					throw new Error(response.errors[0].message)
				} else {
					throw new Error("UNEXPECTED_FORMAT_OR_ERROR");
				}
			}),
			catchError(this.handleError<Record<string, any>>())
		)
	}

	getStateId(state: string): Observable<string> {
        const query = {
            "type": "regions",
            "slug": state
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "id");

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', {params: options})
        .pipe(
            tap(() => console.log('fetched region info')),
            map((data: Record<string, any>) => {
                return data.objects[0].id;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }


    getBestBooksById(stateId: string): Observable<Record<string, any>> {
        const query = {
            "type": "dimers-best-book-details",
            'metadata.state': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "slug,title,metadata,content")
            .set("depth" , 2);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
            tap(() => console.log('fetched data')),
            map((data: Record<string, any>) => {
                return data.objects[0];
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getSportsBooksById(stateId: string): Observable<Array<Record<string, any>>> {
        const query = {
            "type": "sportsbooks",
            'metadata.states': stateId
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query))
            .set("props", "slug,title,metadata")
            .set("depth" , 1);

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug + '/objects', { params: options }).pipe(
            tap(() => console.log('fetched data')),
            map((data: Record<string, any>) => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getBestBooksPromos(stateId: string): Observable<Array<Record<string, any>>> {
        const query = {
            "type": "sportsbook-promos",
            '$and':[
                {
                'metadata.state':stateId
                },
                {
                'metadata.atad_clients':'649e2cc35849ef00087a7b3a'
                }
            ]
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);


        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            tap(() => console.log('fetched promos data')),
            map((data: Record<string, any>) => {
                return data.objects;
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

	getCosmicOffersByStateId(stateId: string) {
        const query = {
            "type": "sportsbook-promos",
            '$and':[
                {
                'metadata.state':stateId
                },
                {
                'metadata.atad_clients':'649e2cc35849ef00087a7b3a'
                }
            ]
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);


        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            tap(() => console.log('fetched promos data')),
            map(data => data.objects),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getSportsbookReview(sportsbook: string) {

        let slug = sportsbook + '-sportsbook-review';
        const query = {
            "type": "dimers-sportsbook-review-details",
            "slug": slug
        };

        const options = new HttpParams()
            .set("read_key", this.cosmicReadKey)
            .set("query", JSON.stringify(query) )
            .set("props", "slug,title,metadata")
            .set("depth" , 1);


        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
            tap(() => console.log('fetched sportsbook review data')),
            map(data => {
                return data.objects[0]
            }),
            shareReplay(1),
            catchError(this.handleError())
        );
    }

    getSportsBooksReviewMethodology(): Observable<Record<string, any>> {
        const query = {
            "type": "sportsbook-review-methodology",
            "slug": 'dimers-sportsbook-review-methodology'
        };

        const options = new HttpParams()
        .set("read_key", this.cosmicReadKey)
        .set("query", JSON.stringify(query) )
        .set("props", "slug,title,metadata,content");

        return this.http.get<any>(this.cosmmicApi + this.cosmicBucketSlug +  '/objects', {params: options}).pipe(
        tap(() => console.log('fetched sportsbooks methodology page')),
        map((data: Record<string, any>) => {
            return data.objects[0];
        }),
        shareReplay(1),
        catchError(this.handleError())
        );
    }



	// timeAgoString(date: Date): string {
	// 	let hoursago = differenceInMilliseconds(date, new Date()) / (1000 * 3600) * -1;
	// 	if (hoursago < 1) {
	// 		// minutes ago
	// 		var minutesago = hoursago * 60;
	// 		return minutesago.toFixed(0) + (minutesago.toFixed(0) == "1" ? " minute ago" : " minutes ago");
	// 	} else if (hoursago < 24) {
	// 		// hours ago
	// 		return hoursago.toFixed(0) + (hoursago.toFixed(0) == "1" ? " hour ago" : " hours ago");
	// 	} else if (hoursago < (24 * 7)) {
	// 		// days ago
	// 		var daysago = hoursago / 24;
	// 		return daysago.toFixed(0) + (daysago.toFixed(0) == "1" ? " day ago" : " days ago");
	// 	} else if (isThisYear(date)) {
	// 		return format(date, "d MMMM")
	// 	} else {
	// 		return format(date, "d MMMM yyyy")
	// 	}
	// }

	convertCategorySlug(categorySlug: string): string {
		if (this.localisationService.sportPrioritySort(SPORTS).some(s => s.code === categorySlug.toUpperCase())) {
			return SPORTS.find(s => s.code === categorySlug.toUpperCase()).shortName;
		}

		return categorySlug.toUpperCase();
	}

	

	private handleError<T>() {
		return (error: any): Observable<T> => {
			return throwError(new Error("CMS_CALL_FAILED " + JSON.stringify(error, ["message", "arguments", "type", "name"])));
		}
	}

}