<div class="nav-section">
    <div class="nav-main">
        <div class="default-nav">
            <div class="nav-item dropdown"
                *ngIf="dropdownSports.length > 0"
                (click)="toggleSportsDropdown()"
                (mouseover)="showSportsDropdown()"
                (mouseout)="hideSportsDropdown()">
                Sports
                <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
                <div class="dropdown-pip" [hidden]="!sportsDropdownOpen"></div>
                <div class="dropdown-shell" [hidden]="!sportsDropdownOpen" (click)="$event.stopPropagation()">
                    <div class="dropdown-menu sport-dropdown">
                        <div class="sport-dropdown-ctr">
                            <div class="sport-list">
                                <div class="sport-item"
                                    *ngFor="let sport of dropdownSports"
                                    (mouseover)="setDropdownHighlightedSport(sport.code)"
                                    [class.highlighted]="dropdownHighlightedSportCode === sport.code">
                                    <span>{{ sport.shortName }}</span>
                                    <fa-icon class="right-arrow" [hidden]="dropdownHighlightedSportCode !== sport.code" [icon]="faChevronRight"></fa-icon>
                                </div>
                                <div class="sport-item"
                                    (mouseover)="setDropdownHighlightedSport('other')"
                                    [class.highlighted]="dropdownHighlightedSportCode === 'other'">
                                    <span>Other Sports</span>
                                    <fa-icon class="right-arrow" [hidden]="dropdownHighlightedSportCode !== 'other'" [icon]="faChevronRight"></fa-icon>
                                </div>
                            </div>
                            <div class="secondary-panel">

                                <div class="sport-links" *ngFor="let sport of dropdownSports" [hidden]="dropdownHighlightedSportCode !== sport.code">
                                    <!-- <div class="secondary-heading">
                                        {{ dropdownHighlightedSport().shortName }}
                                    </div> -->
                                    <a *ngFor="let page of visibleNavigable(sport)"
                                        [routerLink]="page.path"
                                        (click)="toggleSportsDropdown()"
                                        [queryParams]="page.queryParams">
                                        {{ page.name }}
                                    </a>
                                </div>

								<!-- <div class="extra-links" *ngIf="dropdownHighlightedSport() && (featuredArticles[dropdownHighlightedSport().code] || upcomingMatches[dropdownHighlightedSport().code])">
                                    <div class="match-links" *ngIf="upcomingMatches[dropdownHighlightedSport().code]">
                                        <div class="matches-title">Upcoming Matches</div>
                                        <a class="match-link" *ngFor="let match of upcomingMatches[dropdownHighlightedSport().code]" routerLink="/sport-hub/{{ match.MatchData.Sport.toLowerCase() }}/schedule/{{ matchService.trimMatchID(match.MatchData.SIMatchID) }}"
                                            (click)="toggleSportsDropdown()">
                                            <span *ngIf="dropdownHighlightedSport().code !== 'TEN' && environment.matchFirstTeam === 'away'">
                                                {{ match.MatchData.AwayTeam.DisplayName }} vs. {{ match.MatchData.HomeTeam.DisplayName }}
                                            </span>
                                            <span *ngIf="dropdownHighlightedSport().code !== 'TEN' && environment.matchFirstTeam !== 'away'">
                                                {{ match.MatchData.HomeTeam.DisplayName }} vs. {{ match.MatchData.AwayTeam.DisplayName }}
                                            </span>
                                            <span *ngIf="dropdownHighlightedSport().code === 'TEN'">
                                                {{ match.MatchData.PlayerData.player1.nameDetails.first }} {{ match.MatchData.PlayerData.player1.nameDetails.last }} vs. {{ match.MatchData.PlayerData.player2.nameDetails.first }} {{ match.MatchData.PlayerData.player2.nameDetails.last }}
                                            </span>
                                            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
                                        </a>
                                    </div>
                                    <div class="article-links" *ngIf="featuredArticles[dropdownHighlightedSport().code]">
                                        <div class="articles-title">Featured Article</div>
                                        <a class="article-link"
                                            (click)="toggleSportsDropdown()"
                                            *ngFor="let article of featuredArticles[dropdownHighlightedSport().code]"
                                            routerLink="/news/{{ article.slug }}">
                                            <app-article-image-container class="article-image" imageSrc="{{ article.thumbnail.dynamic_url }}?w=100">
		                                    </app-article-image-container>
                                            <div class="article-text">
                                                <div class="article-title">
                                                    {{ article.short_title }}
                                                </div>
                                            </div>

                                        </a>
                                    </div>
                                </div> -->

								<div class="other-sports-ctr">
									<!-- <div class="secondary-heading" *ngIf="!dropdownHighlightedSport()">
                                        All Other Sports
                                    </div> -->
                                    <div class="other-sports" [hidden]="dropdownHighlightedSportCode !== 'other'">
                                        <a *ngFor="let sport of otherSports"
                                            (click)="toggleSportsDropdown()"
                                            [routerLink]="visibleNavigable(sport)[0].path"
                                            [queryParams]="visibleNavigable(sport)[0].queryParams">
                                            {{ sport.shortName }}
                                        </a>
                                    </div>
                                </div>

							</div>
						</div>
					</div>
				</div>

			</div>
            
            <a routerLink="/best-bets" class="nav-item link" *ngIf="environment.availableSports.length > 0">Best Bets</a>
            <a routerLink="/prop-bets" class="nav-item link" *ngIf="environment.optionalFeatures.includes('props')">Best Props</a>
            <a routerLink="/live-now" class="nav-item link" *ngIf="environment.liveCoverageSports.length > 0">
                <!-- <div class="live-beacon" *ngIf="this.liveLightVisible"></div> -->
                Live Now
            </a>
            <div class="nav-item dropdown"
                *ngIf="environment.optionalFeatures.includes('best-books')"
                (click)="toggleLegalStatesDropdown()"
                (mouseover)="showLegalStatesDropdown()"
                (mouseout)="hideLegalStatesDropdown()">
                US Legal Betting
                <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
                <div class="dropdown-pip" [hidden]="!legalStatesDropdownOpen"></div>
                <div class="dropdown-shell centred" [hidden]="!legalStatesDropdownOpen">
                    <div class="dropdown-menu">
                        <div class="more-link-list">
                            <ng-container *ngFor="let state of states">
								<a class="more-link" [routerLink]="['/betting', (state.name).toLowerCase().replace(' ', '-')]" >
									<div class="icon-text-combo">
										<img loading="lazy" [src]="state.iconLink" class="book-icon"
											height="1" width="1">
										{{ state.name }}

									</div>
								</a>
							</ng-container>

							<a class="more-link" routerLink="best-books">
								<div class="icon-text-combo">
									<img loading="lazy" src="https://cdn.cosmicjs.com/872b64b0-10bf-11ee-925e-ebfb5caceee1-United-states.svg" class="book-icon"
										height="1" width="1">
									All Other States
								</div>
							</a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="nav-item dropdown"
                *ngIf="environment.siteTitle === 'Dimers'"
                (click)="toggleBooksDropdown()"
                (mouseover)="showBooksDropdown()"
                (mouseout)="hideBooksDropdown()">
                US Sportsbooks
                <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
                <div class="dropdown-pip" [hidden]="!booksDropdownOpen"></div>
                <div class="dropdown-shell centred" [hidden]="!booksDropdownOpen">
                    <div class="dropdown-menu">
                        <div class="more-link-list">
                            <a class="more-link" href="/betting/reviews/fanduel" routerLink="betting/reviews/fanduel">
                                <div class="icon-text-combo">
                                    <img height="1" width="1" alt="logo" class="book-icon" src="https://cdn.cosmicjs.com/c1788490-10b5-11ee-925e-ebfb5caceee1-Fanduel-Icon-Logo.png">
                                    FanDuel
                                </div>
                            </a>
                            <a class="more-link" href="/betting/reviews/draftkings" routerLink="betting/reviews/draftkings">
                                <div class="icon-text-combo">
                                    <img height="1" width="1" alt="logo" class="book-icon" src="https://cdn.cosmicjs.com/8c1167e0-10b5-11ee-925e-ebfb5caceee1-Draftkings-Icon-Logo.png">
                                    DraftKings
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <ng-container *ngFor="let link of environment.additionalNavOptions">
                <a *ngIf="!link.subLinks"
                    class="nav-item link"
                    [routerLink]="link.routerLink"
                    [queryParams]="link.queryParams"
                >
                    {{ link.title }}
                </a>
                <div class="nav-item dropdown" *ngIf="link.subLinks">
                    {{ link.title }}
                    <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
                    <div class="dropdown-pip show-on-hover"></div>
                    <div class="dropdown-shell show-on-hover">
                        <div class="dropdown-menu">
                            <div class="more-link-list">
                                <a class="more-link" *ngFor="let subLink of link.subLinks" [routerLink]="subLink.routerLink" [queryParams]="subLink.queryParams">
                                    {{ subLink.title }}
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
            <div class="nav-item dropdown"
                (click)="toggleMoreDropdown()"
                (mouseover)="showMoreDropdown()"
                (mouseout)="hideMoreDropdown()">
                <fa-icon class="more-icon" [icon]="faEllipsisH" aria-label="More" role="button"></fa-icon>
                <div class="dropdown-pip" [hidden]="!moreDropdownOpen"></div>
                <div class="dropdown-shell centred" [hidden]="!moreDropdownOpen">
                    <div class="dropdown-menu">
                        <div class="more-link-list">
                            <a class="more-link" routerLink="/parlay-picker" *ngIf="environment.optionalFeatures.includes('parlay-picker')">
                                Parlay Picker
                            </a>
                            <a class="more-link" routerLink="/news">
                                {{ environment.siteTitle === 'Radar Sports' ? 'All Articles' : 'News' }}
                            </a>
                            <a class="more-link" routerLink="/news/authors">
                                Contributors
                            </a>
                            <a class="more-link" routerLink="/about-us">
                                About Us
                            </a>
                            <!-- <a class="more-link" routerLink="/free-to-play">
                                Free to Play
                            </a> -->
                            <a class="more-link" routerLink="/sports-betting-101" *ngIf="environment.optionalFeatures.includes('sports-betting-101')">
                                Betting Explained
                            </a>
                            <!-- <a class="more-link" routerLink="/betting-calculator">
                                Betting Calculator
                            </a> -->
                            <a class="more-link" routerLink="/podcasts" *ngIf="environment.optionalFeatures.includes('podcasts')">
                                Podcasts
                            </a>
                            <!-- <a class="more-link" routerLink="/super-bowl-squares">
                                Super Bowl Squares
                            </a> -->
							<!-- <a class="more-link" routerLink="/contributors">
                                Contributors
                            </a> -->
                            <span class="more-link" (click)="fireNewsletterPopUp()" *ngIf="environment.optionalFeatures.includes('newsletter')">
                                Join Newsletter
                            </span>
                            <a class="more-link" href="https://shop.dimers.com/" target="_blank" *ngIf="environment.optionalFeatures.includes('shop')">
                                Dimers Shop
                            </a>
                            <!-- <a class="more-link" routerLink="/bet-center" *ngIf="environment.optionalFeatures.includes('bet-center')">
                                Bet Center
                            </a> -->
                            <a class="more-link" routerLink="/responsible-gambling" *ngIf="environment.optionalFeatures.includes('responsible-gambling-page')">
                                Gamble Responsibly
                            </a>
                        </div>
                    </div>
                </div>

			</div>
		</div>
		<!-- <div class="search-section" *ngIf="searchBarActive">
            <fa-icon [icon]="faSearch" aria-label="Search"></fa-icon>
            <div class="close-search" (click)="closeSearch()">Close</div>
        </div> -->
	</div>

	<div class="nav-extras">
		<!-- <input *ngIf="searchBarActive" type="text" [(ngModel)]="searchText" class="search-input"
            placeholder="Search..."
            (keyup.enter)="submitSearch()">
        <div *ngIf="searchBarActive" class="search-close-button" (click)="closeSearch()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>
        <div class="search-button" (click)="advanceSearch()">

            <fa-icon [icon]="faSearch" aria-label="Search"></fa-icon>
        </div> -->
        <!-- <a href="https://shop.dimers.com" class="business-button" *ngIf="environment.optionalFeatures.includes('shop')">
            Dimers Shop
        </a> -->
		<!-- <a routerLink="/dimers-pro" class="pro-button">
            <img
                class="pro-logo"
                src="https://cdn.ciphersports.io/images/dimers-site/dimers-pro-logo.svg"
                alt="Dimers Pro"
            >
        </a> -->
        <!-- <app-login-button></app-login-button> -->
        <ng-container *ngIf="!environment.auth0Domain && environment.optionalFeatures.includes('newsletter')">
            <div class="signup-button" (click)="fireNewsletterPopUp()">Join Newsletter</div>
        </ng-container>
        <ng-container *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && authHelper.user">
            <div class="user-name">
                <div class="nav-item dropdown"
                    (click)="toggleAccountDropdown()"
                    (mouseover)="showAccountDropdown()"
                    (mouseout)="hideAccountDropdown()">
                    <div class="user-icon-ctr">
                        <img class="user-icon" [src]="authHelper.user.picture">
                    </div>
                    <!-- <span class="name-string">
                        Hi {{ authHelper.user.user_metadata && authHelper.user.user_metadata.screenname ? authHelper.user.user_metadata.screenname : authHelper.user.name }}
                    </span> -->
                    <fa-icon class="down-arrow" [icon]="faChevronDown" aria-hidden="true"></fa-icon>
                    <div class="dropdown-pip" [hidden]="!accountDropdownOpen"></div>
                    <div class="dropdown-shell" [hidden]="!accountDropdownOpen">
                        <div class="dropdown-menu">
                            <div class="more-link-list">
                                <a class="more-link" routerLink="/my-account">
                                    My Account
                                </a>
                                <a class="more-link" (click)="authHelper.logout({ returnTo: document.location.origin })">
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>

				</div>
			</div>
		</ng-container>

        <ng-container *ngIf="environment.auth0Domain && authHelper.userRetrievalDone && !authHelper.user">
            <div class="login-button" (click)="authHelper.goToLoginWithState()">Log in</div>
            <div class="signup-button" (click)="authHelper.goToLoginWithState(true)">Sign up for free</div>
        </ng-container>

	</div>
</div>
