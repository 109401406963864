import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalisationService } from './localisation.service';
import { SPORTS } from './sports';

@Injectable({
  providedIn: 'root'
})
export class MatchLinkConversionGuard  {
  constructor(
    private router: Router,
    private localisationService: LocalisationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // if required query parameter "matchid" is not present:
    if (!route.queryParamMap.has("matchid")) {
      // go through to default component (an error page)
      return true;
    }

    const matchIDParam = route.queryParamMap.get("matchid");

    // if the match id parameter doesn't have an underscore in it:
    if (!matchIDParam.includes("_")) {
      // go through to default component (an error page)
      return true;
    }

    // text before the first underscore corresponds to a sport code; remainder corresponds to a specific match
    const [sportCode, matchCode] = matchIDParam.toLowerCase().split(/_(.+)/);

    if (!this.localisationService.sportPrioritySort(SPORTS).some(s => s.code === sportCode.toUpperCase())) {
      // go through to default component (an error page)
      return true;
    }

    // redirect the user to the new format of match centre route
    this.router.navigateByUrl(`/sport-hub/${sportCode.toLowerCase()}/schedule/${matchCode.toLowerCase()}`);
    return false;

  }
  
}
