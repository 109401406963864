<div class="content">
  <div class="heading">
    More from {{ environment.siteTitle }}
  </div>
<section class="accordion">
  <section class="accordion-tabs" #subLinkTabs>
       <button class="accordion-tab schedule" data-actab-group="0" data-actab-id="0" (click)="this.toggleShow('schedule')"
        [class.accordion-active]="activeSubFooterCategory === 'schedule'">
        predictions
       </button>
       <button class="accordion-tab best-bets" data-actab-group="0" data-actab-id="1" (click)="this.toggleShow('best-bets')"
        [class.accordion-active]="activeSubFooterCategory === 'best-bets'">
        best bets
       </button>
       <button class="accordion-tab best-props" data-actab-group="0" data-actab-id="2" (click)="this.toggleShow('best-props')"
        [class.accordion-active]="activeSubFooterCategory === 'best-props'">
        best props
       </button>
       <!-- <button class="accordion-tab super-bowl" data-actab-group="0" data-actab-id="3" (click)="this.toggleShow('super-bowl')"
        [class.accordion-active]="activeSubFooterCategory === 'super-bowl'">
        super bowl
       </button> -->
       <button class="accordion-tab best-trends" data-actab-group="0" data-actab-id="3" (click)="this.toggleShow('best-trends')"
        [class.accordion-active]="activeSubFooterCategory === 'best-trends'">
        best trends
       </button>
       <button class="accordion-tab best-books" data-actab-group="0" data-actab-id="4" (click)="this.toggleShow('best-books')"
        [class.accordion-active]="activeSubFooterCategory === 'best-books'">
        Best Sportsbooks
       </button>
       <button class="accordion-tab futures" data-actab-group="0" data-actab-id="5" (click)="this.toggleShow('futures')"
        [class.accordion-active]="activeSubFooterCategory === 'futures'">
        futures
       </button>
       <button class="accordion-tab odds" data-actab-group="0" data-actab-id="6" (click)="this.toggleShow('odds')"
        [class.accordion-active]="activeSubFooterCategory === 'odds'">
        odds
       </button>
       <button class="accordion-tab news articles" data-actab-group="0" data-actab-id="7" (click)="this.toggleShow('news')"
        [class.accordion-active]="activeSubFooterCategory === 'news' || activeSubFooterCategory === 'articles'">
        news
       </button>
  </section>
  <section class="according-content" #subLinkItems>
    <article class="accordion-item schedule" data-actab-group="0" data-actab-id="0"
      [class.accordion-active]="activeSubFooterCategory === 'schedule'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('schedule')" >Predictions</h4>
      <div class="accordion-item__container">
        <ul>
            <ng-container *ngFor = "let link of predictionLinks">
                <li>
                  <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
                  <a [routerLink]="link.path"> {{ link.shortName }} Predictions</a>
                </li>
            </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-bets" data-actab-group="0" data-actab-id="1"
      [class.accordion-active]="activeSubFooterCategory === 'best-bets'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('best-bets')">Best Bets</h4>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of bestBetsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Best Bets</a>
            </li>
        </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-props" data-actab-group="0" data-actab-id="2"
      [class.accordion-active]="activeSubFooterCategory === 'best-props'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('best-props')">Best Props</h4>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of bestPropsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Best Props</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <!-- <article  class="accordion-item super-bowl" data-actab-group="0" data-actab-id="3"
      [class.accordion-active]="activeSubFooterCategory === 'super-bowl'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('super-bowl')">Super Bowl</h4>
      <div class="accordion-item__container">
        <ul>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/super-bowl"> Super Bowl LVII</a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/super-bowl/prop-bets-sheet"> Super Bowl Props Sheet </a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/super-bowl/betting-guide"> Super Bowl Betting Guide</a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/super-bowl/squares"> Super Bowl Squares</a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/super-bowl/party-pack"> Super Bowl Party pack</a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="/news/super-bowl-lvii-first-anytime-touchdown-scorers-predictions-31128"> Super Bowl Touchdown Scorers</a>
          </li>
          <li>
            <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
              height="18" width="18">
            <a routerLink="news/super-bowl-best-prop-bets-for-chiefs-vs-eagles-31121"> Super Bowl Best Props</a>
          </li>
        </ul>
      </div>
    </article> -->
    <article class="accordion-item best-trends" data-actab-group="0" data-actab-id="3"
      [class.accordion-active]="activeSubFooterCategory === 'best-trends'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('best-trends')">Best Trends</h4>
      <div class="accordion-item__container">
        <ul>
          <li><app-sport-icon code="NBA" colour="black"></app-sport-icon><a routerLink="/best-trends/nba"> NBA Best Trends</a></li>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-books" data-actab-group="0" data-actab-id="4"
      [class.accordion-active]="activeSubFooterCategory === 'best-books'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('best-books')">Best Sportsbooks</h4>
      <div class="accordion-item__container">
        <!-- <ul class="best-books">
          <li class="" *ngFor="let book of bestBooksOptions" >
            <a class=""  [routerLink]="book.link">
              <div class="icon-text-combo">
                  <img loading="lazy" [src]="book.icon" class="book-icon"
                      height="18" width="18">
                  {{ book.name }}
              </div>
          </a>
					</li>
        </ul> -->
        <ul>
          <li><app-sportsbooks-icon sportBookCode="arizona"></app-sportsbooks-icon><a routerLink="/betting/arizona">Best Sportsbooks Arizona</a></li>
          <li><app-sportsbooks-icon sportBookCode="colorado"></app-sportsbooks-icon><a routerLink="/betting/colorado">Best Sportsbooks Colorado</a></li>
          <li><app-sportsbooks-icon sportBookCode="illinois"></app-sportsbooks-icon><a routerLink="/betting/illinois">Best Sportsbooks Illinois</a></li>
          <li><app-sportsbooks-icon sportBookCode="louisiana"></app-sportsbooks-icon><a routerLink="/betting/louisiana">Best Sportsbooks Louisiana</a></li>
          <li><app-sportsbooks-icon sportBookCode="maryland"></app-sportsbooks-icon><a routerLink="/betting/maryland">Best Sportsbooks Maryland</a></li>
          <li><app-sportsbooks-icon sportBookCode="massachusetts"></app-sportsbooks-icon><a routerLink="/betting/massachusetts">Best Sportsbooks Massachusetts</a></li>
          <li><app-sportsbooks-icon sportBookCode="new-jersey"></app-sportsbooks-icon><a routerLink="/betting/new-jersey">Best Sportsbooks New Jersey</a></li>
          <li><app-sportsbooks-icon sportBookCode="new-york"></app-sportsbooks-icon><a routerLink="/betting/new-york">Best Sportsbooks New York</a></li>
          <li><app-sportsbooks-icon sportBookCode="ohio"></app-sportsbooks-icon><a routerLink="/betting/ohio">Best Sportsbooks Ohio</a></li>
          <li><app-sportsbooks-icon sportBookCode="pennsylvania"></app-sportsbooks-icon><a routerLink="/betting/pennsylvania">Best Sportsbooks Pennsylvania</a></li>
          <li><app-sportsbooks-icon sportBookCode="kentucky"></app-sportsbooks-icon><a routerLink="/betting/kentucky">Best Sportsbooks Kentucky</a></li>
          <li><app-sportsbooks-icon sportBookCode="iowa"></app-sportsbooks-icon><a routerLink="/betting/iowa">Best Sportsbooks Iowa</a></li>
        </ul>
      </div>
    </article>
    <article class="accordion-item futures" data-actab-group="0" data-actab-id="5"
      [class.accordion-active]="activeSubFooterCategory === 'futures'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('futures')">Futures</h4>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of futuresLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Futures</a>
            </li>
          </ng-container>
          <!-- <li><app-sport-icon code="NFL" colour="black"></app-sport-icon><a routerLink="/futures/nfl"> NFL Futures</a></li>
          <li><app-sport-icon code="NBA" colour="black"></app-sport-icon><a routerLink="/futures/nba"> NBA Futures</a></li>
          <li><app-sport-icon code="NHL" colour="black"></app-sport-icon><a routerLink="/futures/nhl"> NHL Futures</a></li>
          <li><app-sport-icon code="CFB" colour="black"></app-sport-icon><a routerLink="/futures/cfb"> College Football Futures</a></li>
          <li><app-sport-icon code="CBB" colour="black"></app-sport-icon><a routerLink="/futures/cbb"> College Basketball Futures</a></li>
          <li><app-sport-icon code="EPL" colour="black"></app-sport-icon><a routerLink="/futures/epl"> Premier League Futures</a></li>
          <li><app-sport-icon code="ESP" colour="black"></app-sport-icon><a routerLink="/futures/esp"> La Liga Futures</a></li> -->
        </ul>
      </div>
    </article>
    <article class="accordion-item odds" data-actab-group="0" data-actab-id="6"
      [class.accordion-active]="activeSubFooterCategory === 'odds'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('odds')">Odds</h4>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of oddsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path" [queryParams]="link.queryParams"> {{ link.shortName }} Odds</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item news articles" data-actab-group="0" data-actab-id="7"
      [class.accordion-active]="activeSubFooterCategory === 'news' || activeSubFooterCategory === 'articles'">
      <h4 class="accordion-item__label" (click)="this.toggleShow('news')">News</h4>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of newsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path" [queryParams]="link.queryParams"> {{ link.shortName }} News</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>

  </section>
</section>
</div>
