import { Component } from '@angular/core';
import { BlogService } from 'src/app/blog.service';

@Component({
  selector: 'app-callback-page',
  templateUrl: './callback-page.component.html',
  styleUrls: ['./callback-page.component.scss']
})
export class CallbackPageComponent {

  constructor(
    public blogService: BlogService,
  ) { }

}
