import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { ErrorPageComponent } from './general/error-page/error-page.component';
import { SearchResultsPageComponent } from './general/search-results-page/search-results-page.component';
import { CallbackPageComponent } from './general/callback-page/callback-page.component';

import { LandingPageGuard } from "./landing-page.guard";
import { PromoRedirectGuard } from "./promo-redirect.guard";
import { ArticleLinkGuard } from "./article-link.guard"
import { ValidSportGuard } from "./valid-sport.guard"
import { OptionalFeaturePageGuard } from "./optional-feature-page.guard";
import { MatchLinkConversionGuard } from "./match-link-conversion.guard";
import { environment } from 'src/environments/environment';

const routes: Routes = [
	{path: "blog", redirectTo: "news"},
	environment.aboutPageHTML ? {path: "about-us", loadChildren: () => import('./about/about.module').then(m => m.AboutModule)} : null,
	{path: "contributor/:author", redirectTo: "news/author/:author"},
	{path: "contributors/:author", redirectTo: "news/author/:author"},
	{path: "contributors", redirectTo: "blog/authors"},
	{path: "news", loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule), data: {breadcrumb: 'News'}},
	{path: "super-bowl-squares", redirectTo: "super-bowl/squares"},
	{path: "super-bowl", loadChildren: () => import('./nfl/nfl.module').then(m => m.NflModule)},
	{path: "march-madness", loadChildren: () => import('./cbb/cbb.module').then(m => m.CbbModule)},
	{path: "articles", redirectTo: "news"},
	{path: "callback", component: CallbackPageComponent},
	{path: "futures/:sport", redirectTo: "sport-hub/:sport/futures"},
	{path: "parlay-picker", loadChildren: () => import('./parlay-picker/parlay-picker.module').then(m => m.ParlayPickerModule), data: { breadcrumb: 'Parlay Picker'}, canActivate: [OptionalFeaturePageGuard]},
	{path: ":sport/futures", redirectTo: "sport-hub/:sport/futures"},
	{path: "swc/preview", redirectTo: "sport-hub/swc/preview"},
	{path: "sport-hub/swc/preview", loadChildren: () => import('./swc-preview/swc-preview.module').then(m => m.SwcPreviewModule)},
	{path: "sport-hub/:sport/futures", loadChildren: () => import('./futures/futures.module').then(m => m.FuturesModule)},
	{path: ":sport/ladder", redirectTo: "sport-hub/:sport/futures"},
	{path: ":sport/sackometer", redirectTo: "sport-hub/:sport/pressure-gauge"},
	{path: ":sport/pressure-gauge", redirectTo: "sport-hub/:sport/pressure-gauge"},
	{path: "sport-hub/:sport/pressure-gauge", loadChildren: () => import('./sackometer/sackometer.module').then(m => m.SackometerModule)},
	{path: ":sport/true-kicker", redirectTo: "sport-hub/:sport/true-kicker"},
	{path: "sport-hub/:sport/true-kicker", loadChildren: () => import('./true-kicker/true-kicker.module').then(m => m.TrueKickerModule), pathMatch: "full"},
	{path: ":sport/tipping", redirectTo: "sport-hub/:sport/tipping"},
	{path: "sport-hub/:sport/tipping", loadChildren: () => import('./tipping/tipping.module').then(m => m.TippingModule)},
	{path: ":sport/sos", redirectTo: "sport-hub/:sport/sos"},
	{path: "sport-hub/:sport/sos", loadChildren: () => import('./sched-diff/sched-diff.module').then(m => m.SchedDiffModule)},
	{path: ":sport/brownlow", redirectTo: "sport-hub/:sport/brownlow"},
	{path: "sport-hub/:sport/brownlow", loadChildren: () => import('./brownlow/brownlow.module').then(m => m.BrownlowModule)},
	{path: ":sport/shot-charting", redirectTo: "sport-hub/:sport/shot-charting"},
	{path: "sport-hub/:sport/shot-charting", loadChildren: () => import('./shot-charting/shot-charting.module').then(m => m.ShotChartingModule)},
	{path: ":sport/left-right", redirectTo: "sport-hub/:sport/left-right"},
	{path: "sport-hub/:sport/left-right", loadChildren: () => import('./try-loc/try-loc.module').then(m => m.TryLocModule)},
	{path: "cbb/simulator", redirectTo: "march-madness/simulator"},
	{path: ":sport/simulator", redirectTo: "sport-hub/:sport/simulator"},
	// {path: "sport-hub/:sport/simulator", loadChildren: () => import('./simulator/simulator.module').then(m => m.SimulatorModule)},
	{path: "simulator", redirectTo: "sport-hub/swc/simulator"},
	{path: ":sport/match", component: ErrorPageComponent, canActivate: [MatchLinkConversionGuard]},
	{path: "sport-hub/:sport/schedule/:matchid", loadChildren: () => import('./match-centre/match-centre.module').then(m => m.MatchCentreModule)},
	{path: "live", redirectTo: "live-now", pathMatch: "full"},
	{path: "live-now", loadChildren: () => import('./live/live.module').then(m => m.LiveModule), data: {breadcrumb: 'Live Now'}},
	{path: ":sport/round", redirectTo: "sport-hub/:sport/schedule"},
	{path: "best-trends/:sport", loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule), canActivate: [OptionalFeaturePageGuard]},
	// {path: ":sport/articles", redirectTo: "news"},
	{path: "value-summary", redirectTo: "best-bets"},
	{path: "quick-picks", redirectTo: "best-bets"},
	{path: "best-props", redirectTo: "prop-bets"},
	{path: "best-bets", loadChildren: () => import('./value-summary/value-summary.module').then(m => m.ValueSummaryModule), data: {breadcrumb: 'Best Bets'}},
	{path: "prop-bets", loadChildren: () => import('./value-summary-props/value-summary-props.module').then(m => m.ValueSummaryPropsModule), canActivate: [OptionalFeaturePageGuard]},
	{path: "podcasts", loadChildren: () => import('./podcasts/podcasts.module').then(m => m.PodcastsModule), data: {breadcrumb: 'Podcasts'}},
	{path: "news-letter", loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule)},
	{path: "newsletter", redirectTo: "news-letter"},
	{path: "sports-betting-101", loadChildren: () => import('./sports-betting-101/sports-betting-101.module').then(m => m.SportsBetting101Module), data: {breadcrumb: 'Sports Betting 101'}},
	{path: "free-to-play", loadChildren: () => import('./free-to-play/free-to-play.module').then(m => m.FreeToPlayModule)},
	{path: ":sport/player-dna", redirectTo: "sport-hub/:sport/player-dna"},
	{path: "sport-hub/:sport/player-dna", loadChildren: () => import('./player-dna/player-dna.module').then(m => m.PlayerDNAModule)},
	{path: ":sport/player-ratings", redirectTo: "sport-hub/:sport/player-ratings"},
	{path: "sport-hub/:sport/player-ratings", loadChildren: () => import('./player-ratings/player-ratings.module').then(m => m.PlayerRatingsModule)},
	{path: "golf/tournament", redirectTo: "sport-hub/golf/next"},
	{path: "sport-hub/golf", loadChildren: () => import('./golf/golf.module').then(m => m.GolfModule)},
	// {path: "club20", loadChildren: () => import('./club20/club20.module').then(m => m.Club20Module)},
	// {path: "business", loadChildren: () => import('./b2b/b2b.module').then(m => m.B2BModule)},
	{path: "sport-hub/racing",  redirectTo: "/news?sport=racing"},
	// {path: "sport-hub/racing", loadChildren: () => import('./racing/racing.module').then(m => m.RacingModule)},
	{path: "racing", redirectTo: "/news?sport=racing"},
	{path: "raceday", redirectTo: "/news?sport=racing"},
	{path: "racecheatsheet", redirectTo: "/news?sport=racing"},
	{path: "raceschedule", redirectTo: "/news?sport=racing"},
	{path: "sport-hub/:sport/schedule", loadChildren: () => import('./fixtures/fixtures.module').then(m => m.FixturesModule)},
	{path: "racepage", redirectTo: "/news?sport=racing"},
	{path: "my-account", loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
	{path: "responsible-gambling", loadChildren: () => import('./gamble-responsibly/gamble-responsibly.module').then(m => m.GambleResponsiblyModule)},
	{path: "contact", loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
	{path: "the-data", redirectTo: "help/the-data"},
	{path: "punting-info", redirectTo: "help/punting-info"},
	{path: "jobs", redirectTo: "help/jobs"},
	{path: "search", component: SearchResultsPageComponent},
	{path: "ten/rankings", redirectTo: "sport-hub/ten/rankings"},
	{path: "sport-hub/ten/rankings", loadChildren: () => import('./tennis/tennis.module').then(m => m.TennisModule)},
	{path: "ten/results", redirectTo: "ten/round"},
	{path: "", pathMatch: "full", loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule), data: {breadcrumb: 'Home'}},
	{path: "home", redirectTo: "/"},
	{path: "notfound", component: ErrorPageComponent},
	{path: "preview/:slug", redirectTo: "news/preview/:slug", pathMatch: "full"},
	{path: "privacy", redirectTo: "privacy-policy", pathMatch: "full"},
	environment.privacyPolicyHTML ? {path: "privacy-policy", loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule)} : null,
	{path: "bet-hub", redirectTo: "sport-hub"},
	{path: "sport-hub/:sport", loadChildren: () => import('./hub/hub.module').then(m => m.HubModule), pathMatch: "full", canActivate: [ValidSportGuard]},
	{path: "terms", redirectTo: "terms-of-service", pathMatch: "full"},
	environment.termsPageHTML ? {path: "terms-of-service", loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)} : null,
	{path: "best-books", redirectTo: "betting"},
	{path: "betting", loadChildren: () => import('./bookmakers/bookmakers.module').then(m => m.BookmakersModule), data: {breadcrumb: {alias: 'bestBooks'}}},
	{path: "best-odds", loadChildren: () => import('./best-odds/best-odds.module').then(m => m.BestOddsModule)},
	// {path: "bet-center", loadChildren: () => import('./my-bets/my-bets.module').then(m => m.MyBetsModule), data: {breadcrumb: 'Bet Center'}},
	{path: "sitemap", loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule)},
	{path: "go/:param1", canActivate: [PromoRedirectGuard], children: []},
	{path: "go/:param1/:param2", canActivate: [PromoRedirectGuard], children: []},
	{path: "go/:param1/:param2/:param3", canActivate: [PromoRedirectGuard], children: []},
	// {path: ":sport/:slug", redirectTo: "news/:slug"},
	{path: "sport-hub/:sport/:slug", redirectTo: "news/:slug"},
	// {path: ":pagecode", loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule), canActivate: [LandingPageGuard], data: { breadcrumb: {alias: 'landingPageTitle'}}},
	{path: "**", loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule), canActivate: [LandingPageGuard], data: { breadcrumb: {alias: 'landingPageTitle'}}},
].filter(r => r !== null) as Array<Route>;

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled',
})],
	exports: [RouterModule]
})
export class AppRoutingModule { }