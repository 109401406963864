import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFahrenheit'
})
export class ToFahrenheitPipe implements PipeTransform {

  transform(celsiusValue: number): number {
    return (celsiusValue * 9 / 5) + 32;
  }

}
