import { AfterContentChecked, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalisationService } from 'src/app/localisation.service';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';

@Component({
  selector: 'app-sport-scrollbar',
  templateUrl: './sport-scrollbar.component.html',
  styleUrls: ['./sport-scrollbar.component.scss']
})
export class SportScrollbarComponent implements OnInit, AfterContentChecked {
  
  mobileLinksAtStart = true;
  mobileLinksAtEnd = true;
  firstChangedPass = true;
  sports: Array<Sport> = [];

  @ViewChild("mobileLinkTrack") mobileLinkTrack;

  constructor(
    private localisationService: LocalisationService,
    private router: Router,
  ) {
    this.sports = this.localisationService.sportPrioritySort(SPORTS.filter(s => s.active && !s.hideFromMainNav));
  }

  visibleNavigable(sport: Sport): Array<any> {
		return sport.navigablePages.filter(p => !p.hideCondition);
	}

  ngOnInit(): void {
    this.checkScrollTapers();
  }

  ngAfterContentChecked(): void {
    // console.log("ngAfterContentChecked");
    if (typeof document !== "undefined") {
      const scrollBar = this.mobileLinkTrack?.nativeElement;
      if (scrollBar && this.firstChangedPass) {
        this.firstChangedPass = false;
        this.mobileLinksAtStart = scrollBar.scrollLeft === 0;
        this.mobileLinksAtEnd = scrollBar.scrollLeft + scrollBar.offsetWidth >= scrollBar.scrollWidth;
      }
    } else if (this.firstChangedPass) {
      this.firstChangedPass = false;
      this.mobileLinksAtStart = true;
      this.mobileLinksAtEnd = false;
    }

  
  }

  checkScrollTapers(): void {
    if (typeof document !== "undefined") {
      const scrollBar = this.mobileLinkTrack?.nativeElement;
      if (scrollBar) {
        this.mobileLinksAtStart = scrollBar.scrollLeft === 0;
        this.mobileLinksAtEnd = scrollBar.scrollLeft + scrollBar.offsetWidth >= scrollBar.scrollWidth;
      }
    } else {
      this.mobileLinksAtStart = true;
      this.mobileLinksAtEnd = false;
    }
    
  }

  // ngOnInit(): void {
  // }

}
