import { Component, Inject, OnInit, Optional } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { LocalisationService } from './localisation.service';
import { HOST_NAME } from "./host-name";
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = environment.siteTitle;
	updatePromptVisible = false;
	
	newsletterPopUpVisible = false;
	showScore: boolean = true;
	showPartner: boolean = true;

	environment = environment;

	constructor(
		private swUpdate: SwUpdate, 
		private localisationService: LocalisationService,
		private router: Router,
	) {
		this.localisationService.setLocale(environment.localeString);

		this.router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				if (event['url'] == '/responsible-gambling') {
					this.showScore = false;
					this.showPartner = false;
				} else {
					this.showScore = true;
					this.showPartner = true;
				}
			}
		});
	}

	onHomePage(): boolean {
		return this.router.url === "/";
	}

	ngOnInit() {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates
				.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
				)
				.subscribe(() => {
					console.log("New Version Available");
					this.updatePromptVisible = true;
				});
		}
	}

	reloadPage() {
		if (typeof window !== "undefined") {
			window.location.reload();
		}
	}

	fireNewsletterPopUp = (): void => {
		this.newsletterPopUpVisible = true;
	}

	hideNewsletterPopUp = (): void => {
		this.newsletterPopUpVisible = false;
	}
	
	faRedoAlt = faRedoAlt;
}
