import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCelsius'
})
export class ToCelsiusPipe implements PipeTransform {

  transform(fahrenheitValue: number): number {
    return (fahrenheitValue - 32) * 5 / 9;
  }

}
