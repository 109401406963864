import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject, EMPTY, of } from 'rxjs';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { catchError, map, switchMap } from "rxjs/operators";
import { FixtureRound } from "src/app/fixture-round.model";
import { TeamFuturesRecord } from "src/app/team-futures-record.model";
import { TennisTournament } from "src/app/tennis-tournament.model";
import { Match } from "./match.model";
import { MatchLiveData } from './match-live-data.model';
import { HorseRaceRunner } from './horse-race-runner.model';
import { HorseRaceDay } from './horse-race-day.model';
import { HorseRaceMeeting } from './horse-race-meeting.model';
import { HorseRaceMaxEdgeRecord } from './horse-race-max-edge-record.model';
import { SPORTS } from './sports';
import { addDays, compareAsc, compareDesc, differenceInMinutes, format, isFuture, parseISO, parseJSON } from 'date-fns';
import { TennisFuturesRecord } from "./tennis-futures-record.model"
import { TennisRankingsObject } from "./tennis-rankings-object.model";
import { GolfTournament } from "./golf-tournament.model";
import { LocalisationService } from './localisation.service';
import { environment } from 'src/environments/environment';
import { MatchPropBet } from './match-prop-bet.model';
import { MatchTeam } from './match-team.model';
import { SackometerCoach } from './sackometer-coach.model';
import { ShotChartSummary } from './shot-chart-summary.model';
import { ShotChartPoint } from './shot-chart-point.model';

@Injectable({
	providedIn: 'root'
})
export class SportDataService {

	constructor(
		private http: HttpClient,
		private localisationService: LocalisationService,
	) { }

	// TODO
	// getActiveMatchesLiveStats(): Observable<Object[]> {
	// 	return of([]);
	// }

	// TODO
	// getDetailedMatchStats(matchID: string): Observable<Object> {
	// 	return of({});
	// }

	// TODO
	// getFuturesData(sportCode: string): Observable<TeamFuturesRecord[]> {
	// 	return of([]);
	// }

	// TODO
	// getGolfTournamentData(season: number, tournamentID: number): Observable<Object> {
	// 	return of({});
	// }

	// TODO
	// getGroupStageStandings(sportCode: string): Observable<Object[]> {
	// 	return of([]);
	// }

	getLatestShotChartingRound(sportCode: string): Observable<number> {
		return this.http.get<Array<Record<string, number>>>(`https://gazza.statsinsider.com.au/shotcharts/latest.json?sport=${sportCode}`)
			.pipe(
				map(records => records[0].latestRound),
				catchError(this.handleError<number>())
			)
	}

	getRoundOptions(sportCode: string): Observable<Array<FixtureRound>> {
		return this.http.get<Array<FixtureRound>>(`${this.apiDomain}/round/options?Sport=${sportCode.toUpperCase()}`)
			.pipe(
				map((rounds) => {
					if (rounds && rounds.length > 0) {
						const roundOptions = rounds.sort((a, b) => {
							if (a.Year !== b.Year) {
								return a.Year - b.Year;
							}

							return a.Round_Number - b.Round_Number;
						});

						return roundOptions;

					} else {
						return [];
					}
				}),
				catchError(this.handleError<Array<FixtureRound>>())
			);
	}

	// TODO
	// getMatchLiveStats(matchID: string): Observable<MatchLiveData> {
	// 	return of({});
	// }

	
	// TODO
	// getMatchLiveStatsLegacy(matchID: string): Observable<MatchLiveData> {
	// 	return of({});
	// }

	// TODO
	// getMultipleMatchLiveStatsLegacy(matchIDs: string[]): Observable<MatchLiveData[]> {
	// 	return of([]);
	// }

	getPlayerRatingData(sportCode: string, full: boolean): Observable<Object[]> {
		return this.http.get<Array<Object[]>>(`https://gazza.statsinsider.com.au/playerdata.json?sport=${sportCode}&short=${!full}`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			);
	}

	getTrueKickerData(sportCode: string, span: string): Observable<Object[]> {
		return this.http.get<Array<Object[]>>(`https://gazza.statsinsider.com.au/true-kicker.json?sport=${sportCode}&span=${span}`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			);
	}

	getMatchData(matchID: string, bestBets?: boolean, overrideBookmakers?: Array<string>): Observable<Match> {
		return this.http.get<Match>(`${environment.apiDomain}/pre/${matchID}${bestBets ? `?best_bets=true&bookmakers=${(overrideBookmakers ? overrideBookmakers : environment.bookmakers).join(",")}` : ""}`)
			.pipe(
				catchError(this.handleError<Match>())
			)
	}

	// TODO
	// getPreMatchPlayerDetails(matchID: string): Observable<Object> {
	// 	return of({});
	// }

	// TODO
	// getPreviousMeetings(sportCode: string, team1Code: string, team2Code: string, endDate: string): Observable<Object[]> {
	// 	return of([]);
	// }

	getBrownlowData(): Observable<Array<Record<string, any>>> {
		return this.http.get<Array<Record<string, any>>>(`${environment.apiDomain}/futures?Sport=BROWNLOW`)
			.pipe(
				catchError(this.handleError<Array<Record<string, any>>>())
			)
	}



	racetrackOffset(trackName: string): string {
        switch (trackName) {
            case "Doomben":
            case "Ipswich":
            case "Eagle Farm":
            case "Gold Coast":
            case "Sunshine Coast":
            case "Rockhampton":
            case "Toowoomba":
            case "Mackay":
            case "Cairns":
            case "Beaudesert":
                return "+1000";
            default:
                return "+1000"; // TODO: Change this back to "+1100" at the start of Daylight Savings in southern states
        }
    }

	getRaceDayInfo(date?: Date): Observable<HorseRaceDay> {
		const serviceRef = this;
        return this.http.get<HorseRaceRunner[]>(`https://gazza.statsinsider.com.au/racing/form-day.json?date=${format(date, 'yyyy-MM-dd')}`)
            .pipe(
				switchMap(horses => {
                

					horses = horses.map(h => this.processRaceHorse(h, date));

					return this.http.get<Array<HorseRaceMaxEdgeRecord>>(`https://gazza.statsinsider.com.au/racing/max-edge.json?date=${format(date, 'yyyy-MM-dd')}`)
						.pipe(
							map(pastEdgeHorses => {
								horses.forEach(h => {
									var maxH = pastEdgeHorses.find(maxH => 
										maxH.horse_name == h.horse_name && maxH.track_3char_abbrev == h.track_3char_abbrev
										&& maxH.race_number == h.race_number
									);
									
									if (typeof maxH != 'undefined') {
										h.maxEdge = maxH.maxEdgePct;
									} else {
										h.maxEdge = 0;
									}
								})
	
	
								var meetingNames = [...new Set(horses.map(h => h.track_name))];
								var meetings: Array<HorseRaceMeeting> = meetingNames.map(mn => {
									var meetingHorses = horses.filter(h => h.track_name === mn)
									var raceNumbers = [...new Set(meetingHorses.map(mh => mh.race_number))]
									var locationCode = horses.find(h => h.track_name === mn).track_3char_abbrev;
	
									try {
										return {
										location: mn,
										locationCode: locationCode,
										date: date,
										races: raceNumbers.map(rn => {
											var raceHorses = meetingHorses.filter(h => h.race_number === rn);
											return {
												raceNum: rn,
												time: raceHorses[0].raceTime,
												race_class: raceHorses[0].race_class,
												timeString: raceHorses[0].timeString,
												prizePool: raceHorses[0].race_prizepool,
												topEdge: Math.max(...raceHorses.filter(h => h.starting_price != 'SCR').map(h => h.edgePct)),
												display: "rating",
												raceParams: {
													day: date.getDate(),
													mth: date.getMonth() + 1,
													year: date.getFullYear().toString(),
													meet: locationCode,
													race: rn
												},
												horses: raceHorses.map(h => {
													var newH = h;
													return newH;
												}),
												location: null,
												minsDifference: null,
											}
										})
									} } catch (err) {console.log("Meeting failed: " + err); return null;};
								}).filter(r => r !== null);
	
								const allRaces = meetings
									.map((m) => m.races.map(r => {
										var newR = r;
										newR.location = m.location;
										newR.locationCode = m.locationCode;
										newR.minsDifference = differenceInMinutes(newR.time, new Date());
										return newR;
									}))
									.reduce((acc, val) => acc.concat(val), []);
	
								if (meetings.length > 0) {
									var maxRaceArray = [...Array(Math.max.apply(Math, allRaces.map(r => r.raceNum))).keys()];
								} else {
									maxRaceArray = [];
								}
	
								return {
									date: date,
									raceMeets: meetings,
									maxRaceArray: maxRaceArray,
									allRaces: allRaces,
								};
							}),
							catchError(this.handleError<HorseRaceDay>())
						)
        		}),
				catchError(this.handleError<HorseRaceDay>())
			);
	}

	getNextRaceDay(): Observable<Date> {
		return this.http.get(`https://gazza.statsinsider.com.au/racing/next-date.txt`, { responseType: "text"})
            .pipe(
				map((dateString: string) => {
					if (dateString.length > 0) {
						return parseISO(dateString.split(" ")[0]);
					} else {
						return null;
					}
				}),
				catchError(this.handleError<Date>())
			);
	}

	getCurrentGolfTournamentName(): Observable<string> {
		return this.http.get<any>(`${environment.apiDomain}/tournaments`)
			.pipe(
				map((response: Array<any>) => {
					response.forEach(t => {
						t.startDateConverted = parseJSON(t.startDate);
						t.endDateConverted = parseJSON(t.endDate);
					});

					if (response.some(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)) {
						const nextTournament = response.filter(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)
							.sort((a,b) => compareAsc(a.startDateConverted, b.startDateConverted))[0];
						return nextTournament.tournamentName;
					} else {
						let latestTournament;
						if (response.some(t => t.futuresDataAvailable)) {
							latestTournament = response.filter(t => t.futuresDataAvailable)
								.sort((a,b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						} else {
							latestTournament = response.sort((a,b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						}
						
						return latestTournament.tournamentName;
					}
						
					
					
				}),
				catchError(this.handleError<string>())
			);
		

	}

	processRaceHorse(h: HorseRaceRunner, date: Date): HorseRaceRunner {
		h.horse_silk = `https://www.aapmegaform.com.au/racing/horse-colours.aspx?event-id=${h.race_id}&horse-id=${h.horse_id}&type=png&key=${h.horse_silk_key}`;

		const trackCategory = h.race_going[0];

		if (trackCategory == "M") { // Firm
			h.goingGrade = h.form_firm_win_assess.toLowerCase();
		} else if (trackCategory == "G") { // Good
			h.goingGrade = h.form_good_win_assess.toLowerCase();
		} else if (trackCategory == "O") { // Soft
			h.goingGrade = h.form_soft_win_assess.toLowerCase();
		} else if (trackCategory == "H") { // Heavy
			h.goingGrade = h.form_heavy_win_assess.toLowerCase();
		} else {
			h.goingGrade = "grey";
		}
		
		// add code to determine price category based on win price

		if (typeof h.winOdds !== "number") {
			h.priceCategory = "N/A";
		} else if (h.winOdds < 2) {
			h.priceCategory = "Hot Shot";
		} else if (h.winOdds <= 8) {
			h.priceCategory = "Mid-Range";
		} else {
			h.priceCategory = "Roughie";
		}

		
		const gradePoints = {
			green: 30,
			orange: 10,
			grey: 0,
			red: -10,
		};

		h.totalPoints = gradePoints[h.form_distance_win_assess.toLowerCase()] + gradePoints[h.goingGrade]
			+ gradePoints[h.form_track_win_assess.toLowerCase()] + gradePoints[h.value_win_assess.toLowerCase()];

		// make the maximum possible score 100
		h.totalPoints = h.totalPoints * (100/120)

		// number of grades which are green
		h.greenGrades = [h.form_distance_win_assess.toLowerCase(), h.goingGrade, h.form_track_win_assess.toLowerCase(),
			h.value_win_assess.toLowerCase()].filter(grade => grade == "green").length;

		var dateString = format(date, 'yyyy-MM-dd')
		var timeParts = h.race_time.match(/^([0-9]+)\:([0-9]+)(\w+)$/);
		var timeOffset = (timeParts[3] == "pm" && timeParts[1] !== '12' ? 12 : (timeParts[3] == "am" && timeParts[1] == "12" ? -12 : 0));
		h.raceTime = parseISO(`${dateString}T${parseInt(timeParts[1]) + timeOffset}:${timeParts[2]}:00${this.racetrackOffset(h.track_name)}`);
		h.timeString = format(h.raceTime, "h:mm");
		h.minsDifference = differenceInMinutes(h.raceTime, new Date());


		return h;
	}

	getRaceDayInfoWithHighestEdge(date: Date): Observable<HorseRaceMaxEdgeRecord[]> {
		return this.http.get<Array<HorseRaceMaxEdgeRecord>>(`https://gazza.statsinsider.com.au/racing/max-edge.json?date=${format(date, 'yyyy-MM-dd')}`)
			.pipe(
				catchError(this.handleError<Array<HorseRaceMaxEdgeRecord>>())
			)
	}

	getTennisRankingsData(): Observable<TennisRankingsObject[]> {
		return this.http.get<Array<TennisRankingsObject>>(`https://gazza.statsinsider.com.au/rankings.json?sport=TEN`)
			.pipe(
				catchError(this.handleError<Array<TennisRankingsObject>>())
			)
		
	}

	getGolfRankingsData(): Observable<Object[]> {
		return this.http.get<Array<Object>>(`https://gazza.statsinsider.com.au/golf/rankings.json`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			)
		
	}

	getGolfH2HMatrix(tournamentID: string, season: number): Observable<Object[]> {
		return this.http.get<Array<Object>>(`https://gazza.statsinsider.com.au/golf/h2h.json?tid=${tournamentID}&year=${season}`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			)
	}

	getNextGolfTournamentData(): Observable<GolfTournament> {
		return this.http.get<Array<GolfTournament>>(`${environment.apiDomain}/tournaments`)
			.pipe(
				switchMap((tournaments: Array<GolfTournament>) => {
					tournaments.forEach(t => {
						t.startDateConverted = parseJSON(t.startDate);
						t.endDateConverted = parseJSON(t.endDate);
					});

					let tournamentID: string, season: number;

					if (tournaments.some(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)) {
						const nextTournament = tournaments.filter(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)
							.sort((a,b) => compareAsc(a.startDateConverted, b.startDateConverted))[0];
						tournamentID = nextTournament.tournamentId;
						season = nextTournament.year;
					} else {
						let latestTournament;
						if (tournaments.some(t => t.futuresDataAvailable)) {
							latestTournament = tournaments.filter(t => t.futuresDataAvailable)
								.sort((a,b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						} else {
							latestTournament = tournaments.sort((a,b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						}
						
						tournamentID = latestTournament.tournamentId;
						season = latestTournament.year;
					}
					
					return this.http.get<GolfTournament>(`${environment.apiDomain}/tournament/${tournamentID}/${season}?futures=true`)
						.pipe(
							map(futuresObject => {
								return futuresObject;
							}),
							catchError(this.handleError<GolfTournament>())
						)

				}),
				catchError(this.handleError<GolfTournament>())
			);
	}

	getRoundMatches(
		sportCode: string,
		roundNo: number,
		season: number,
		strip = false,
		bestBets = false,
		bookmakerList?: Array<string>
	): Observable<Array<Match>> {
		return this.http.get<Array<Match>>(`${this.apiDomain}/round/matches` +
			`?Sport=${sportCode.toUpperCase()}` +
			`&Round=${roundNo}` +
			`&Season=${season}` +
			`${strip ? "&strip=true" : ""}` +
			`${bestBets ? `&best_bets=true&bookmakers=${bookmakerList ? bookmakerList.join(",") : "none"}` : ""}`)
			.pipe(
				catchError(this.handleError<Array<Match>>())
			);
	}

	getRoundTippingVotes(sportCode: string, roundNo: number, season: number): Observable<Array<{team: string, "count(id)": number}>> {
		// catch for NRL round numbers being fixed to two digits
		let roundString: string;
		if (sportCode.toUpperCase() === "NRL") {
			roundString = roundNo.toString().padStart(2, "0");
		} else {
			roundString = roundNo.toString();
		}
		return this.http.get<Array<{team: string, "count(id)": number}>>(`https://gazza.statsinsider.com.au/allvotes.json?roundid=${sportCode.toUpperCase()}_${season}_${roundString}`)
			.pipe(
				catchError(this.handleError<Array<{team: string, "count(id)": number}>>())
			)
	}

	getSackometerData(sportCode: string): Observable<SackometerCoach[]> {
		return this.http.get<Array<SackometerCoach>>(`https://gazza.statsinsider.com.au/sacko.json?sport=${sportCode.toUpperCase()}`)
			.pipe(
				catchError(this.handleError<Array<SackometerCoach>>())
			)
	}

	getScheduleDifficultyData(sportCode: string): Observable<Object[]> {
		return this.http.get<Array<Object>>(`https://gazza.statsinsider.com.au/strength-of-schedule/sos.json?sport=${sportCode.toUpperCase()}`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			)
	}

	getShotChartingPlayers(sportCode: string): Observable<Array<ShotChartSummary>> {
		return this.http.get<Array<ShotChartSummary>>(`https://gazza.statsinsider.com.au/shotcharts/names.json?sport=${sportCode}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartSummary>>())
			)
	}

	getTeamAggregateShotChartSummary(sportCode: string, teamCode: string, startRoundCode: number, endRoundCode: number): Observable<Array<ShotChartSummary>> {
		return this.http.get<Array<ShotChartSummary>>(`https://gazza.statsinsider.com.au/shotcharts/team-summary.json?sport=${sportCode}&team=${teamCode}&startDate=${startRoundCode}&endDate=${endRoundCode}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartSummary>>())
			)
	}

	getTeamPlayerShotChartSummaries(sportCode: string, teamCode: string, startRoundCode: number, endRoundCode: number): Observable<Array<ShotChartSummary>> {
		return this.http.get<Array<ShotChartSummary>>(`https://gazza.statsinsider.com.au/shotcharts/summary.json?sport=${sportCode}&team=${teamCode}&startDate=${startRoundCode}&endDate=${endRoundCode}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartSummary>>())
			);
	}

	getTeamShotChartPoints(sportCode: string, teamCode: string): Observable<Array<ShotChartPoint>> {
		return this.http.get<Array<ShotChartPoint>>(`https://gazza.statsinsider.com.au/shotcharts/shot-data.json?sport=${sportCode}&team=${teamCode}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartPoint>>())
			)
	}

	getPlayerShotChartPoints(sportCode: string, playerName: string): Observable<Array<ShotChartPoint>> {
		return this.http.get<Array<ShotChartPoint>>(`https://gazza.statsinsider.com.au/shotcharts/shot-data.json?sport=${sportCode}&player=${playerName}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartPoint>>())
			);
	}

	getPlayerShotChartSummary(sportCode: string, playerName: string, startRoundCode: number, endRoundCode: number): Observable<Array<ShotChartSummary>> {
		return this.http.get<Array<ShotChartSummary>>(`https://gazza.statsinsider.com.au/shotcharts/summary.json?sport=${sportCode}&player=${playerName}&startDate=${startRoundCode}&endDate=${endRoundCode}`)
			.pipe(
				catchError(this.handleError<Array<ShotChartSummary>>())
			);
	}

	// TODO
	getSimulatorMatchupMatrix(sportCode: string, tournamentID?: number): Observable<Array<Record<string, any>>> {
		if (sportCode.toUpperCase() === "SWC") {
			return this.http.get<Array<Record<string, any>>>(`https://gazza.statsinsider.com.au/eurosim-matches.json`)
				.pipe(
					catchError(this.handleError<Array<Record<string, any>>>())
				);
		}
		if (sportCode.toUpperCase() === "CBB") {
			return this.http.get<Array<Record<string, any>>>(`https://gazza.statsinsider.com.au/cbbsim-matches.json`)
				.pipe(
					catchError(this.handleError<Array<Record<string, any>>>())
				);
		}
		return of([]);
	}

	// TODO
	getSimulatorRandomGroupStages(sportCode: string): Observable<Array<Record<string, any>>> {
		if (sportCode.toUpperCase() === "SWC") {
			return this.http.get<Array<Record<string, any>>>(`https://gazza.statsinsider.com.au/eurosim-groups.json`)
				.pipe(
					map(response => {
						return response;
					}),
					catchError(this.handleError<Array<Record<string, any>>>())
				);
		}
		return of([]);
	}

	getTournamentFuturesData(sportCode: string, season?: number, tournamentID?: string): Observable<Array<Record<string, any>>> {
		if (sportCode.toUpperCase() === "TEN") {
			return this.http.get<Array<TennisFuturesRecord>>(`https://gazza.statsinsider.com.au/tennissim-players.json?tid=${tournamentID}`)
				.pipe(
					map((dict: Array<TennisFuturesRecord>) => {
						return dict.filter(r => r.Player !== "Bye" && r.Player !== "Bye/Qualifier")
							.map(row => {
								const splitName = row.Player.split(", ");
								let surname: string, firstName: string, displayName: string;
								if (splitName.length == 1) {
									surname = splitName[0].match(/^([\w ]+) (\w+)$/)[2];
									firstName = splitName[0].match(/^([\w ]+) (\w+)$/)[1];
									displayName = firstName.split(" ").map(s => s[0] + ". ").join("") + surname
								} else {
									surname = splitName[0];
									firstName = splitName[1];
									displayName = firstName.split(" ").map(s => s[0] + ". ").join("") + surname;
								}

								return {
									tournamentName: row.tournamentName,
									Country: row.Country,
									Surname: surname,
									FirstName: firstName,
									Short: displayName,

									Winner: (row.Winner / 100 || 0),
									WinSemi: (row.WinSemi / 100 || 0),
									WinQuarter: (row.WinQuarter / 100 || 0),
								}
							})
					}),
					catchError(this.handleError<Array<TennisFuturesRecord>>())
				)
			
		}
		if (sportCode.toUpperCase() === "CBB") {
			return this.http.get<Array<Record<string, any>>>(`https://gazza.statsinsider.com.au/cbbsim-players.json`)
				.pipe(
					catchError(this.handleError<Array<Record<string, any>>>())
				);
		}
	}

	// TODO
	// getTournamentMatches(sportCode: string, season: number, tournamentID: number): Observable<Object[]> {
	// 	return of([]);
	// }

	// TODO
	// getTournaments(sportCode: string): Observable<Object[]> {
	// 	return of([]);
	// }

	getTryLocationAnalysisData(sportCode: string): Observable<Object[]> {
		return this.http.get<Array<Object>>(`https://gazza.statsinsider.com.au/try-charts/summary.json?sport=${sportCode.toUpperCase()}`)
			.pipe(
				catchError(this.handleError<Array<Object>>())
			)
		
	}

	sendTippingVote(matchID: string, teamCode: string): Observable<any> {
		return this.http.get(`https://gazza.statsinsider.com.au/addvote?matchid=${matchID}&team=${teamCode}`, {responseType: "text"})
			.pipe(
				catchError(this.handleError<any>())
			)
	}

	anyLiveMatchesOn(): Observable<boolean> {
		return this.http.get<Record<string, Array<MatchLiveData>>>(`${environment.apiDomain}/matches/live`)
			.pipe(
				map((dict: Record<string, Array<MatchLiveData>>) => {
					return Object.values(dict).some(a => a.some(m => this.localisationService.sportPrioritySort(SPORTS).map(s => s.code).includes(m.Sport) && ["inprogress", "live", "halftime"].includes(m.status)));
				}),
				catchError(this.handleError<boolean>())
			)
	}

	getUpcomingMatches(sportCodes?: Array<string>, strip?: boolean, bestBets?: boolean, bookmakerList?: Array<string>, sportExclusiveBookmakers?: Record<string, string>): Observable<Array<Match>> {
		if (!sportCodes) {
			sportCodes = this.localisationService.sportPrioritySort(SPORTS).map(s => s.code);
		}

		return this.http.post<Array<Match>>(`${environment.apiDomain}/matches/upcoming?Sport=${sportCodes.join(",")}${strip ? "&strip=true" : ""}${bestBets ? `&best_bets=true&bookmakers=${bookmakerList.join(",")}` : ""}`,{
			sport_exclusive_bookmakers: sportExclusiveBookmakers
		})
			.pipe(
				catchError(this.handleError<Array<Match>>())
			)
	}

	private handleError<T>() {
		return (error: any): Observable<T> => {
			return throwError(new Error("API_CALL_FAILED " + JSON.stringify(error, ["message", "arguments", "type", "name"])));
		}
	}

	apiDomain: string = environment.apiDomain;


	getFuturesTeams(sport: string): Observable<Array<TeamFuturesRecord>> {
		return this.http.get<Array<TeamFuturesRecord>>(`${this.apiDomain}/futures?Sport=${sport}`)
			.pipe(
				catchError(this.handleError<Array<TeamFuturesRecord>>())
			);
	}

	connectToMatchPushStream(matchID: string): WebSocketSubject<any> {
		return this.getNewMatchWebSocket(matchID);
	}

	connectToUpcomingPushStream(sportCodes: Array<string>): WebSocketSubject<any> {
		return this.getNewUpcomingWebSocket(sportCodes);
	}

	getUpcomingGames(sports: Array<string>, strip = false, bestBets = false, bookmakerList?: Array<string>, sportExclusiveBookmakers?: Record<string, string>, tournamentName?: string): Observable<Array<Match>> {
		return this.http.post<Array<Match>>(`${this.apiDomain}/matches/upcoming?Sport=${sports.join(",")}`
			+ `${strip ? "&strip=true" : ""}` +
			`${tournamentName ? `&tournament_name=${tournamentName}` : ""}` +
			`${bestBets ? `&best_bets=true&bookmakers=${bookmakerList ? bookmakerList.join(",") : "none"}` : ""}`, {
				sport_exclusive_bookmakers: sportExclusiveBookmakers
			})
			.pipe(
				catchError(this.handleError<Array<Match>>())
			);
	}

	getPreMatchData(matchID: string, bestBets = false, bookmakerList?: Array<string>): Observable<Match> {
		return this.http.get<Match>(`${this.apiDomain}/pre/${matchID}` +
			`${bestBets ? `?best_bets=true&bookmakers=${bookmakerList ? bookmakerList.join(",") : "none"}` : ""}`)
			.pipe(
				catchError(this.handleError<Match>())
			);
	}

	getLiveMatchData(matchID: string): Observable<MatchLiveData> {
		return this.http.get<MatchLiveData>(`${this.apiDomain}/live/${matchID}`)
			.pipe(
				catchError(this.handleError<MatchLiveData>())
			);
	}

	getPropsQuickPicksData(sports: Array<string>, bookmakerList?: Array<string>, sportExclusiveBookmakers?: Record<string, string>):
		Observable<{picks: Array<MatchPropBet>, prop_categories: Record<string, Record<string, string>>}> {
		return this.http.post<Record<string, any>>(
			`${this.apiDomain}/props/quickpicks?sport=${sports.join(",")}${bookmakerList ? `&bookmakers=${bookmakerList.join(",")}`: ""}`,
			{
				sport_exclusive_bookmakers: sportExclusiveBookmakers
			}
		)
		.pipe(
			map((dict: {picks: Array<MatchPropBet>, prop_categories: Record<string, Record<string, string>>}) => {
				return dict;
			}),
			catchError(this.handleError<{picks: Array<MatchPropBet>, prop_categories: Record<string, Record<string, string>>}>())
		);
	}

	getConciseUpcomingMatchData(sports: Array<string>):
		Observable<Array<{SIMatchID: string, Date: Date, HomeTeam: MatchTeam, AwayTeam: MatchTeam, Sport: string, UserDate?: Date}>> {
		return this.http.get<Record<string, any>>(`${this.apiDomain}/matches/upcoming/ids?dates=true&Sport=${sports.join(",")}`)
		.pipe(
			map((dict: Record<string, any>) => {
				return dict.Matches;
			}),
			catchError(this.handleError<Array<Record<string, any>>>())
		);
	}

	getFullMatchData(matchID: string, bestBets = false, bookmakerList?: Array<string>): Observable<Match> {

		return this.http.get<Match>(`${this.apiDomain}/match/${matchID}` +
			`${bestBets ? `?best_bets=true&bookmakers=${bookmakerList ? bookmakerList.join(",") : "none"}` : ""}`)
			.pipe(
				catchError(this.handleError<Match>())
			);
	}

	getTournamentMatches(tournamentID: string, strip = false, bestBets = false, bookmakerList?: Array<string>): Observable<Array<Match>> {
		return this.http.get<Array<Match>>(`${this.apiDomain}/tournament/${tournamentID}?${strip ? "strip=true&" : ""}`
			+ `${bestBets ? `best_bets=true&bookmakers=${bookmakerList ? bookmakerList.join(",") : "none"}` : ""}&`)
			.pipe(
				catchError(this.handleError<Array<Match>>())
			);
	}

	getGolfTournamentData(tournamentID: string, season: string): Observable<any> {
		if (tournamentID === "GOLF_AUTO") {
			return this.http.get<Array<any>>(`${this.apiDomain}/tournaments`)
			.pipe(
				switchMap((tournaments: Array<any>) => {
					tournaments.forEach(t => {
						t.startDateConverted = parseJSON(t.startDate);
						t.endDateConverted = parseJSON(t.endDate);
					});

					let latestTournamentID: string;
					let latestSeason: number;

					if (tournaments.some(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)) {
						const nextTournament = tournaments.filter(t => isFuture(addDays(t.endDateConverted, 1)) && t.futuresDataAvailable)
							.sort((a, b) => compareAsc(a.startDateConverted, b.startDateConverted))[0];
						latestTournamentID = nextTournament.tournamentId;
						latestSeason = nextTournament.year;
					} else {
						let latestTournament;
						if (tournaments.some(t => t.futuresDataAvailable)) {
							latestTournament = tournaments.filter(t => t.futuresDataAvailable)
								.sort((a, b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						} else {
							latestTournament = tournaments.sort((a, b) => compareDesc(a.startDateConverted, b.startDateConverted))[0];
						}

						latestTournamentID = latestTournament.tournamentId;
						latestSeason = latestTournament.year;
					}

					return this.http.get<any>(`${this.apiDomain}/tournament/${latestTournamentID}/${latestSeason}?futures=true`)
						.pipe(
							map(futuresObject => futuresObject),
							catchError(this.handleError<any>())
						);

				}),
				catchError(this.handleError<any>())
			);
		}

		return this.http.get<any>(`${this.apiDomain}/tournament/${tournamentID}/${season}?futures=true`)
			.pipe(
				map(futuresObject => futuresObject),
				catchError(this.handleError<any>())
			);
	}

	getTournamentOptions(): Observable<Array<TennisTournament>> {
		return this.http.get<Array<TennisTournament>>(`${this.apiDomain}/tournaments/tennis`)
			.pipe(
				map((tournaments) => {
					const tournamentOptions = tournaments.sort((a, b) => {
						if (a.Year && b.Year && a.Year !== b.Year) {
							return a.Year - b.Year;
						}

						return compareDesc(parseJSON(a.MaxDate as string), parseJSON(b.MaxDate as string));
					});

					return tournamentOptions;

				}),
				catchError(this.handleError<Array<TennisTournament>>())
		);
	}

	getNBASimulatorTeams(): Observable<Array<Record<string, any>>> {
		return this.http.get<Array<Record<string, any>>>("https://gazza.statsinsider.com.au/nbasim-players.json")
			.pipe(
				catchError(this.handleError<Array<Record<string, any>>>())
			);
	}

	getNBASimulatorMatchupMatrix(): Observable<Array<Record<string, any>>> {
		return this.http.get<Array<Record<string, any>>>("https://gazza.statsinsider.com.au/nbasim-matches.json")
			.pipe(
				catchError(this.handleError<Array<Record<string, any>>>())
			);
	}


	private getNewMatchWebSocket(matchID: string): WebSocketSubject<any> {
		return webSocket("wss://levy-ws.statsinsider.com.au/feed?stream_ids=" + matchID);
	}

	private getNewUpcomingWebSocket(sportCodes: Array<string>): WebSocketSubject<any> {
		return webSocket("wss://levy-ws.statsinsider.com.au/feed?stream_ids=" + sportCodes.map(s => s.toUpperCase()).join(","));
	}

}
