import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faChevronLeft, faChevronDown, faSearch, faSquareUser, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { GoogleTagManagerModule } from 'src/app/angular-google-tag-manager.module';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { SportDataService } from 'src/app/sport-data.service';
import { Sport } from 'src/app/sport.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent {

	sportsDropdownOpen = false;
	mobileSecondarySportMenuActive = false;
	mobileNavVisible = false;
	accountDropdownOpen = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	@Input() withoutScoreStrip = false;
	searchText = "";
	moreDropdownOpen = false;
	booksDropdownOpen = false;

	environment = environment;

	faBars = faBars;
	faSearch = faSearch;
	faChevronLeft = faChevronLeft;
	faChevronRight = faChevronRight;
	// faChevronDown = faChevronDown;
	faCircleUser = faCircleUser;

	constructor(
		@Inject(DOCUMENT) public document: Document,
		private router: Router,
		public authHelper: AuthHelperService,
		private sportDataService: SportDataService,
		private gtmService: GoogleTagManagerService,
	) {
		// ensure that mobile navigation is closed, and both navs are reset, when user goes to a new page
			router.events.subscribe((val) => {
				if (val instanceof NavigationEnd) {
					this.mobileNavVisible = false;
					this.mobileSecondarySportMenuActive = false;
					this.searchText = "";
				}
			})
	}

	submitSearch(): void {
		this.router.navigateByUrl("/search?q=" + this.searchText)
	}

	toggleSportsDropdown(): void {
		this.sportsDropdownOpen = !this.sportsDropdownOpen;

		this.accountDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.booksDropdownOpen = false;
		
		if (!this.sportsDropdownOpen) {
			this.mobileSecondarySportMenuActive = false;
		}
	}

	showSportsDropdown(): void {
		this.sportsDropdownOpen = true;
	}

	hideSportsDropdown(): void {
		this.sportsDropdownOpen = false;
		this.mobileSecondarySportMenuActive = false;
	}

	toggleMobileNav(): void {
		this.mobileNavVisible = !this.mobileNavVisible;

		if (!this.mobileNavVisible) {
			this.moreDropdownOpen = false;
			this.accountDropdownOpen = false;
			this.sportsDropdownOpen = false;
			this.booksDropdownOpen = false;
		}
	}

	hideMobileNav(): void {
		this.mobileNavVisible = false;
	}
	
	toggleAccountDropdown(): void {
		this.accountDropdownOpen = !this.accountDropdownOpen;

		this.sportsDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.booksDropdownOpen = false;
	}
	
	hideAccountDropdown(): void {
		this.accountDropdownOpen = false;
	}

	toggleMoreDropdown(): void {
		this.moreDropdownOpen = !this.moreDropdownOpen;

		this.accountDropdownOpen = false;
		this.sportsDropdownOpen = false;
		this.booksDropdownOpen = false;
	}

	hideMoreDropdown(): void {
		this.moreDropdownOpen = false;
	}

	toggleBooksDropdown(): void {
		this.booksDropdownOpen = !this.booksDropdownOpen;

		this.accountDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.sportsDropdownOpen = false;
	}

	hideBooksDropdown(): void {
		this.booksDropdownOpen = false;
	}

    hideAllDropdown(): void {
        this.hideMoreDropdown();
        this.hideSportsDropdown();
    }
    
	sendTapTag(pageName: string): void {
		const gtmTag = {
			event: "tapped",
			event_category: "Mobile Menu",
			event_label: pageName,
		};
	  
		this.gtmService.pushTag(gtmTag)
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
			});
	}

	visibleNavigable(sport: Sport): Array<any> {
		return sport.navigablePages.filter(p => !p.hideCondition);
	}

}
