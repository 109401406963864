import { Component, Input, ViewEncapsulation } from "@angular/core";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import { BetService } from "src/app/bet.service";
import { GeneralService } from "src/app/general.service";
import { Match } from "src/app/match.model";
import { MatchService } from "src/app/match.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mini-match-tile",
  templateUrl: "./mini-match-tile.component.html",
  styleUrls: ["./mini-match-tile.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MiniMatchTileComponent {

  @Input() match: Match;

  awayBeforeHome = environment.matchFirstTeam === "away";

  faCaretLeft = faCaretLeft;

  constructor(
    public matchService: MatchService,
    public generalService: GeneralService,
    public betService: BetService
  ) { }

}