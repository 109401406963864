import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { of } from 'rxjs';
import { concatMap, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  user: any = null;
  userShortlist = [];
  userRetrieved = false;
  userRetrievalDone = false;

  constructor(private auth: AuthService, private http: HttpClient) {
    if (environment.auth0Domain && typeof window !== "undefined") {
      this.auth.user$
      .pipe(
        concatMap((user) => {
          this.userRetrievalDone = false;
          if (user && user.sub && !this.userRetrieved) {
            this.user = user;
            return this.http.get(encodeURI(`https://${environment.auth0Domain}/api/v2/users/${user.sub}`))
          } else {
            return of([]);
          }
        }),
        pluck("user_metadata")
      )
      .subscribe((meta: any) => {
        if (meta) {
          this.user.user_metadata = meta;
          if (!meta.match_shortlist) {
            this.userShortlist = [];
          } else {
            this.userShortlist = meta.match_shortlist;
          }
        }
  
        this.userRetrieved = true;
        this.userRetrievalDone = true;
        
      });
    }
    
  }

  goToLoginWithState(signUpPanel?: boolean): void {
    // TODO set local storage
    if (environment.auth0Domain) {    
      this.auth.loginWithRedirect({
        appState: {
          target: window.location.pathname + window.location.search
        },
        authorizationParams: {
          redirect_uri: window.location.origin + "/callback",
          screen_hint: signUpPanel ? "signup" : undefined,
          logged_in_at_path: window.location.pathname + window.location.search,
        }
      });
    }
    
  }

  logout(options?: Record<string, any>) {
    if (environment.auth0Domain) {
      this.auth.logout(options);
    }
  }


}
