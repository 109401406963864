import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GoogleTagManagerService } from "./angular-google-tag-manager.service";
import { GeneralService } from "./general.service";
import { MatchBet } from "./match-bet.model";
import { Match } from "./match.model";

@Injectable({
	providedIn: "root"
})
export class BetService {

	customBookmakerLinks: Record<string, string> = {};

	bookmakerAffiliateCodes: Record<string, string> = {};
	
	constructor(
		public generalService: GeneralService,
		public gtmService: GoogleTagManagerService,
	) {
		this.customBookmakerLinks = {
			"draftkings": "https://go.dime.rs/DraftKings-All-Widget",
			"william_hill": "https://go.dime.rs/Caesars-All-Widget",
			"betmgm": "https://go.dime.rs/BetMGM-All-Widget",
			"pointsbet": "https://go.dime.rs/PointsBet-All-Widget",
			"fanduel": "https://go.dime.rs/FanDuel-All-Widget",
			"bet_rivers_in": "https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_600c_&affid=620&siteid=1885&adid=600&c=",
			"bet_rivers_va": "https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_1306c_&affid=620&siteid=1885&adid=1306&c=",
			"bet_rivers_co": "http://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_750c_&affid=620&siteid=1885&adid=750&c=",
			"betway": "https://us.betway.com/sports?s=bwa25046&a=bwaadid141584",
			"tipico": "https://wltipico.adsrv.eacdn.com/C.ashx?btag=a_23b_43c_&affid=23&siteid=23&adid=43&c=",
			"bet365": "https://go.dime.rs/Bet365-All-Widget",
			"bet_365": "https://go.dime.rs/Bet365-All-Widget",
			"unibet": "https://www.csplen.com/C.ashx?btag=a_734b_185c_&affid=179&siteid=734&adid=185&c=",
			"twinspires": "http://wlbetamerica.adsrv.eacdn.com/C.ashx?btag=a_2559b_2321c_&affid=1997&siteid=2559&adid=2321&c=",
			"wynn": "https://go.dime.rs/WynnBet-Generic-Widget",
		};
	}

	populateTileBettingInfo(match: Match): void {
		if (match.MatchData) {



			if (["mlb", "nhl", "afl", "nrl", "bbl"].includes(match.MatchData.Sport.toLowerCase())
				|| this.generalService.isSoccer(match.MatchData.Sport)) {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.HomeOdds || !match.aggregatedBettingInfo.AwayOdds) {
					match.bettingInfoUnavailable = true;
				}
			} else if (match.MatchData.Sport.toLowerCase() === "ten") {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.H2HOdds || !match.aggregatedBettingInfo.H2HOdds.player1
					|| !match.aggregatedBettingInfo.H2HOdds.player2) {
					match.bettingInfoUnavailable = true;
				}
			} else {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.HomeLine || !match.aggregatedBettingInfo.HomeLineWinPct
					|| !match.aggregatedBettingInfo.AwayLineWinPct) {
					match.bettingInfoUnavailable = true;
				}
			}
		}
	}

	setCustomBookmakerLinks(links: Record<string, string>) {
		this.customBookmakerLinks = {...this.customBookmakerLinks, ...links};
	}

	bestBetName(bet: MatchBet, sport: string): string {
		if (bet.type === "h2h") {
			return "Moneyline";
		}
		if (bet.type === "firstset") {
			return "First Set";
		}
		if (bet.type === "total") {
			return "Over/Under";
		}
		if (bet.type === "bts") {
			return "Both Teams to Score";
		}
		if (bet.type === "line") {
			if (sport.toLowerCase() === "mlb") {
				return "Run Line";
			}
			if (sport.toLowerCase() === "nhl") {
				return "Puck Line";
			}

			return "Spread";
		}

		return "";
	}

	valueFireOpacity(edge: number): number {
		// if (edge < 0) {
		// 	return 0;
		// }

		// if (edge >= 10) {
		// 	return 1;
		// }

		// return 0.2 + edge * 0.08;

		if (edge < 4) {
			return 0;
		}

		return 1;
	}

	bookmakerLink(bookieCode: string, deepLinkOptions?: Record<string, any>): string {
		if (deepLinkOptions) {
			// if ((bookieCode === "bet365" || bookieCode === "bet_365")
			// 	&& Object.keys(this.bookmakerAffiliateCodes).includes(bookieCode)) {
			// 	return `http://www.bet365.com/betslip/instantbet/default.aspx?fid=${deepLinkOptions.FID}`
			// 		+ `&participantid=${deepLinkOptions.ID}`
			// 		+ `&affiliatecode=${this.bookmakerAffiliateCodes[bookieCode]}`
			// 		+ `&odds=${deepLinkOptions.Odds}&Instantbet=1&AVG=${deepLinkOptions.AVG}`;
			// }

			if ((bookieCode === "betfair" || bookieCode === "pointsbet_au") && deepLinkOptions.URL) {
				return deepLinkOptions.URL;
			}

			if (["betmgm", "bet_rivers_in", "sugarhouse"].includes(bookieCode) && deepLinkOptions.short_link) {
				return deepLinkOptions.short_link;
			}

			// TODO support for deep links from other bookmakers
		}
		if (Object.keys(this.customBookmakerLinks).includes(bookieCode)) {
			return this.customBookmakerLinks[bookieCode];
		}

		// TODO should we return a generic link that matches the bookie code and just goes to their home page?
		return "";
	}

	stylisedBookName(bookieCode: string): string {
		if (bookieCode === "fanduel") {return "FanDuel"; }
		if (bookieCode === "sugarhouse") {return "SugarHouse"; }
		if (["pointsbet", "pointsbet_au"].includes(bookieCode)) {return "PointsBet"; }
		if (bookieCode === "william_hill") {return "Caesars"; }
		if (bookieCode === "draftkings") {return "DraftKings"; }
		if (bookieCode === "wynn") {return "WynnBET"; }
		if (bookieCode === "betway") {return "Betway"; }
		if (bookieCode === "tipico") {return "Tipico"; }
		if (bookieCode === "unibet") {return "Unibet"; }
		if (bookieCode === "bet365" || bookieCode === "bet_365") {return "Bet365"; }
		if (bookieCode === "betmgm") {return "BetMGM"; }
		if (bookieCode === "betfair") {return "Betfair"; }
		if (bookieCode === "topsport") {return "TopSport"; }
		if (bookieCode === "bluebet") {return "BlueBet"; }
		if (["bet_rivers_in", "bet_rivers_va", "bet_rivers_co"].includes(bookieCode)) {return "BetRivers"; }

		// TODO more bookies
		return "";
	}

	formatOdds(decimalOdds: number): string {
		if (decimalOdds && environment.oddsDisplayFormat === "decimal") {
			return decimalOdds.toFixed(2);
		}

		if (environment.oddsDisplayFormat === "american") {
			if (decimalOdds < 2) {
				const minusNumber = 100 / (decimalOdds - 1);
				return "-" + minusNumber.toFixed(0);
			}

			const plusNumber = (decimalOdds * 100) - 100;
			return "+" + plusNumber.toFixed(0);
		}

		// TODO fractional
		return "";
	}

	goToPromoLink(url: string, bookCode: string, sourceBlock: string): void {
		const gtmTag = {
			event: "exit",
			event_category: sourceBlock,
			event_label: bookCode,
		};
	
		this.gtmService.pushTag(gtmTag)
			.then(() => {
				window.open(url, "_blank");
			})
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
				window.open(url, "_blank");
			});
	}
}
