import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { addDays, differenceInDays } from 'date-fns';
import { CookieService } from "ngx-cookie";
import { BlogService } from 'src/app/blog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscribe-scroll-prompt',
  templateUrl: './subscribe-scroll-prompt.component.html',
  styleUrls: ['./subscribe-scroll-prompt.component.scss']
})
export class SubscribeScrollPromptComponent implements OnInit, AfterViewInit {

  overlayVisible = false;
  overlayClosed = false;
  
  formSending = false;
  formSent = false;
  formError = false;
  formValid = false;
  email = "";
  firstName = "";

  environment = environment;

  faTimes = faTimes;

  @ViewChild("formContainer") containerElement: ElementRef;

  constructor(
    private blogService: BlogService,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    if (typeof document !== "undefined" && typeof window !== "undefined" && environment.newsletterProvider === "surge") {
      // if user has previously subscribed, don't show popup
      if (this.cookieService.hasKey("mtc_id") || (this.cookieService.hasKey("neverShowPopup") && this.cookieService.get("neverShowPopup") === "true")) {
        this.overlayClosed = true;
      }
    }
  }

  ngAfterViewInit(): void {
    if (typeof document !== "undefined" && environment.newsletterProvider === "surge") {
      if (Array.from(this.containerElement.nativeElement.children).some(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/form/generate.js?id=18`)) {
        const oldScript: HTMLScriptElement = Array.from(this.containerElement.nativeElement.children).find(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/form/generate.js?id=18`) as HTMLScriptElement;
        oldScript.remove();
      }
        
      let js;
      js = document.createElement("script");
      js.src = `https://ams.ciphersports.io/form/generate.js?id=18`;
      (this.containerElement.nativeElement as HTMLElement).appendChild(js);
    }
	
	}

  closeOverlay() {
    this.overlayVisible = false;
    this.overlayClosed = true;

    // set cookies that determine when to next show popup (seven days from now)
    this.cookieService.remove("freePicksPopUp");
    this.cookieService.put("initialPopupDate", new Date().toString());
    this.cookieService.put("freePicksPopUp", "true", {expires: addDays(new Date(), 7)});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if (typeof window !== "undefined" && window.scrollY > 1000 && !this.overlayClosed) {
      this.determinePopupVisiblity();
    }
  }

  determinePopupVisiblity(): void {

    // if there's no cookie for when this popup was first shown, set right now as the moment it was first shown
    if (!this.cookieService.hasKey("initialPopupDate")) {
      this.cookieService.put("initialPopupDate", new Date().toString())
    } else {
      // otherwise, check how long ago that first appearance of the popup was
      const initialDate = new Date(this.cookieService.get("initialPopupDate"));
      const diffDays = differenceInDays(initialDate, new Date());
      
      // if it's been more than 30 days, don't show the popup, and clear the Initial Date cookie from the browser
      if (diffDays > 30) {
        this.cookieService.remove("initialPopupDate");
      
      }
      // if it's been less than 7 days, and dismissal cookie wasn't set, show the pop-up and set the dismissal cookie automatically
      else if (diffDays < 7) {
        if (!this.cookieService.hasKey("freePicksPopUp")) {
          this.overlayVisible = true;
          this.cookieService.put("freePicksPopUp", "true");
        }
      }
      // if it's been between 7 and 30 days, clear the date and dismissal cookies and re-run this logic
      else {
        this.cookieService.remove("freePicksPopUp");
        this.cookieService.remove("initialPopupDate");
        this.determinePopupVisiblity();
      }
    }
  }

  sendEmail(): void {

    if (this.formValid) {
      this.formSending = true;
      this.formSent = false;
      this.formError = false;

      this.blogService.addNewsletterEmail(this.email)
        .subscribe(_ => {
          this.formSent = true;
          this.formSending = false;
          this.overlayClosed = true;
          this.overlayVisible = false;

          // make popup never appear again for user
          this.cookieService.put("neverShowPopup", "true");
        }, () => {
          this.formError = true;
          this.formSending = false;
        })
    }
  }

  verifyForm(): void {
    if (!this.email) {
      this.formValid = false;
    } else if (!/.+@.+\..+/.test(this.email)) {
      this.formValid = false;
    } else {
      this.formValid = true;
    }
  }

}
