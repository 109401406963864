<header [class.hidden-scrore]="!this.showScore || !gameBarSports?.length || !environment.optionalFeatures.includes('score-strip')">
	<div class="newsletter-banner" (click)="fireNewsletterPopUp()" *ngIf="environment.siteTitle === 'Dimers' && environment.optionalFeatures.includes('newsletter') && newsletterBannerVisible">
		Bet smarter with {{ environment.siteTitle }}! <span class="cta-text">Join our newsletter</span>
	</div>
	<div class="carousel-shell">
		<div class="logo-carousel-ctr">
			<a routerLink="/" class="si-logo">
				<img [alt]="environment.siteTitle" loading="lazy"
					[src]="environment.defaultLogo">
			</a>
			<div class="match-carousel">
				<app-game-bar-block *ngIf="gameBarSports?.length > 0 && environment.optionalFeatures.includes('score-strip')"
					[sports]="gameBarSports"
				></app-game-bar-block>
			</div>
		</div>
	</div>
	<app-desktop-navigation
		[dropdownSports]="dropdownSports"
		[otherSports]="otherSports"
		[liveLightVisible]="liveLightVisible"
		[fireNewsletterPopUp]="fireNewsletterPopUp"
	></app-desktop-navigation>
	<app-mobile-navigation
		[dropdownSports]="dropdownSports"
		[otherSports]="otherSports"
		[liveLightVisible]="liveLightVisible"
		[fireNewsletterPopUp]="fireNewsletterPopUp"
		[withoutScoreStrip]="!this.showScore || !gameBarSports?.length || !environment.optionalFeatures.includes('score-strip')"
	></app-mobile-navigation>
</header>
