import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Input } from '@angular/core';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';
import { SportDataService } from 'src/app/sport-data.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LocalisationService } from 'src/app/localisation.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';

@Component({
	selector: 'app-site-header',
	templateUrl: './site-header.component.html',
	styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {
	dropdownSports: Array<Sport>;
	otherSports: Array<Sport>;
	liveLightVisible = false;
	gameBarSports: Array<string> = null;
	newsletterBannerVisible = false;

	environment = environment;
	@Input() fireNewsletterPopUp: () => void;
	@Input() showScore: boolean;


	constructor(
		private sportDataService: SportDataService,
		@Inject(PLATFORM_ID) private platformID: object,
		@Inject(DOCUMENT) public document: Document,
		private localisationService: LocalisationService,
		private cookieService: CookieService,
	) { }

	ngOnInit(): void {
		this.dropdownSports = this.localisationService.sportPrioritySort(SPORTS.filter(s => s.active && !s.hideFromMainNav));
		this.otherSports = this.localisationService.sportPrioritySort(SPORTS.filter(s => !s.active && !s.hideFromMainNav));

		this.activateScoreStripBlock();

		if (typeof document !== "undefined") {
			this.checkForLiveMatches();
		}

		if (typeof document !== "undefined" && typeof window !== "undefined" && environment.optionalFeatures.includes("newsletter")) {
			// if user has previously subscribed, don't show banner
			if (!(this.cookieService.hasKey("mtc_id") || (this.cookieService.hasKey("neverShowPopup") && this.cookieService.get("neverShowPopup") === "true"))) {
				this.newsletterBannerVisible = true;
			}
		}
	}
	
	activateScoreStripBlock(): void {
		this.gameBarSports = this.localisationService
			.sportPrioritySort(SPORTS.filter(s => s.active && s.showInScoreStrip && !s.hideFromMainNav)).map(s => s.code);
		
	}

	checkForLiveMatches(): void {
		this.sportDataService.anyLiveMatchesOn()
			.subscribe((anyLive: boolean) => {
				if (anyLive) {
					this.liveLightVisible = true;
				} else {
					this.liveLightVisible = false;
				}

				if (isPlatformBrowser(this.platformID)) {
					// setTimeout(() => this.checkForLiveMatches(), 30000);
				}

			}, _ => {
				this.liveLightVisible = false;

				if (isPlatformBrowser(this.platformID)) {
					// setTimeout(() => this.checkForLiveMatches(), 30000);
				}
			})
	}

}
