import { Pipe, PipeTransform } from '@angular/core';
import { Match } from '../match.model';
import { IsPreMatchOnlyPipe } from './is-pre-match-only.pipe';

@Pipe({
  name: 'isLive',
  pure: false,
})
export class IsLivePipe implements PipeTransform {

  constructor(
    private isPreMatchOnlyPipe: IsPreMatchOnlyPipe
  ) { }

  transform(match: Match): boolean {
    return match && match.LiveData
			&& (match.LiveData.status === "live" || match.LiveData.status === "inprogress" || match.LiveData.status === "halftime")
			&& !this.isPreMatchOnlyPipe.transform(match);
  }

}
