import { Pipe, PipeTransform } from '@angular/core';
import { Match } from '../match.model';
import { IsPreMatchOnlyPipe } from './is-pre-match-only.pipe';

@Pipe({
  name: 'isPaused',
  pure: false,
})
export class IsPausedPipe implements PipeTransform {

  constructor(
    private isPreMatchOnlyPipe: IsPreMatchOnlyPipe,
  ) { }

  transform(match: Match): boolean {
    return this && match.LiveData
			&& (false) // TODO add types of delay, including weather etc
			&& !this.isPreMatchOnlyPipe.transform(match);
  }

}
