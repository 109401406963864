import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OptionalFeaturePageGuard  {
  constructor(
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (route.toString().includes("/prop-bets")) {

      if (environment.optionalFeatures.includes("props")) {
        return true;
      } else {
        this.router.navigate(["/"]);
        return false;
      }
    }

    if (route.toString().includes("/best-trends")) {

      if (environment.optionalFeatures.includes("trends")) {
        return true;
      } else {
        this.router.navigate(["/"]);
        return false;
      }
    }

    if (route.toString().includes("/parlay-picker")) {

      if (environment.optionalFeatures.includes("parlay-picker")) {
        return true;
      } else {
        this.router.navigate(["/"]);
        return false;
      }
    }
  }
}
