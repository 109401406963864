<div class="prompt-overlay" [class.hidden]="!visible">
	<div class="backdrop" (click)="closeOverlay()"></div>
	<div class="prompt-window">
		<div class="newsletter-header">
			<div class="title">{{ environment.newsletterPromptTitle || ("Bet Smarter with " + environment.siteTitle) }}</div>
			<div class="close-button" (click)="closeOverlay()">
				<fa-icon [icon]="faTimes"></fa-icon>
			</div>
		</div>
		<div class="white-line"></div>
		<div class="content">
			<div
				#formContainer
			></div>
			<div class="form-wrapper" *ngIf="environment.newsletterProvider === 'placeholder'">
				<!-- <img
					class="dimers-logo"
					alt=""
					loading="lazy"
					[src]="environment.defaultLogo"
				/>
				<h2 class="m-bottom-30 header-text">
					Get More From {{ environment.siteTitle }}
				</h2> -->
				<div class="subhead-text" *ngIf="environment.newsletterPromptSubtitle">
					{{ environment.newsletterPromptSubtitle}}
				</div>
				<div
					class="form"
					*ngIf="!formSent"
					ref="form"
				>
			
					<input
						type="email"
						[(ngModel)]="email"
						placeholder="Email"
						name="email"
						ngDefaultControl
						(keyup)="verifyForm()"
					>
			
					<div
						color="primary"
						class="submit-button"
						[class.button-enabled-yellow]="formValid && !formSending"
						[class.button-disabled-yellow]="!formValid || formSending"
						(click)="sendEmail()"
					>
						{{ formSending ? 'Please wait...' : 'Sign Me Up' }}
					</div>
				</div>
				<div class="send-info"
					*ngIf="formSent && !formError"
				>
					Thanks for joining. Stay tuned for more!
				</div>
				<div
					*ngIf="formError"
					class="send-info"
				>
					Sorry, something went wrong sending your details. Please try again.
				</div>
				<div class="disclaimer" *ngIf="environment.newsletterPromptTermsText" [innerHTML]="environment.newsletterPromptTermsText"></div>
				<div class="disclaimer" *ngIf="!environment.newsletterPromptTermsText">
					You can unsubscribe at any time. By signing up you agree to our
						<a href="/terms-of-service" target="_blank">Terms of Service</a> and
						<a href="/privacy-policy" target="_blank">Privacy Policy</a>.
				</div>
			</div>
		</div>
	</div>
</div>
