import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-display-ad',
  templateUrl: './display-ad.component.html',
  styleUrls: ['./display-ad.component.scss']
})
export class DisplayAdComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() type: string;
  @Input() sportCode: string;
  @Input() permanent: boolean;
  @Input() largeOnly = false;
  @Input() smallOnly = false;

  adID: string;
  routerWatch: Subscription = null;
  adObject: any = null;
  slot: any = null;
  postInit = false;
  environment = environment;
  visibleAd = false;
  componentGuid = null;

  storedUrl: string;

  
    // 2g-i. build a request manager to tell us once the requests for the ad-server, DM and APS have been made
    requestManager = {
      adserverRequestSent: false,
      dm: false,
      aps: false
    };

  constructor(router: Router) {
    if (typeof window !== "undefined") {
      this.componentGuid = window.crypto.randomUUID();
      this.storedUrl = window.location.pathname;
    }


    // monitor for in-app navigations
    
    if (typeof window !== "undefined" && environment.googleAdsConfig?.adSpaces && environment.googleAdsConfig.customerID) {
      this.routerWatch = router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          // if no stored url available, set this (should be the first page of the session so no reload required)
          if (typeof this.storedUrl === "undefined") {
            this.storedUrl = window.location.pathname;
          }
          // if path or query params have changed
          if (window.location.pathname !== this.storedUrl) {
            // reload the ad
            
            this.storedUrl = window.location.pathname;
            console.log("reloading ad slot " + this.adID);
            if (this.slot) {
              (window as any).googletag.cmd.push(() => {
                (window as any).googletag.pubads().refresh([this.slot]);
              });
            }
          }
            
              
        }
      })
    }
  }

  ngOnInit() {
    if (environment.googleAdsConfig) {
      console.log("app-display-ad ngOnInit " + this.type);
      this.adObject = environment.googleAdsConfig.adSpaces[this.type];
      if (this.adObject) {
        this.adID = this.adObject.id + '+' + this.componentGuid;
      }
    }
    
  }

  ngAfterViewInit() {
    if (environment.googleAdsConfig) {
      console.log("app-display-ad ngAfterViewInit " + this.type);
      if (typeof window !== "undefined" && environment.googleAdsConfig?.adSpaces && environment.googleAdsConfig.customerID) {
        this.visibleAd = ((!this.largeOnly && !this.smallOnly)
        || (this.largeOnly && window.innerWidth >= 780)
        || (this.smallOnly && window.innerWidth < 780));
        this.loadAd();
        this.postInit = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (environment.googleAdsConfig) {
      // if sport code changes, reload ad slot
      if (typeof window !== "undefined" && this.postInit && changes.sportCode && changes.sportCode.currentValue?.toLowerCase() !== changes.sportCode.previousValue?.toLowerCase()) {
        if (this.slot) {
          this.clearAd();
        }
        this.loadAd();
      }
    }
  }

  loadAd() {
    if (this.adObject) {
      console.log("loadAd - " + this.adID +  " - sportCode = " + this.sportCode);
      

      if (this.visibleAd) {
        (window as any).googletag.cmd.push(() => {
          if (this.type === "main-column") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
                
              const mainColumnMapping = (window as any).googletag.sizeMapping()
                .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
                .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
                .build();
              this.slot.defineSizeMapping(mainColumnMapping);
          } else if (this.type === "main-column2") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
                
              const mainColumnMapping = (window as any).googletag.sizeMapping()
                .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
                .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
                .build();
              this.slot.defineSizeMapping(mainColumnMapping);
          } else if (this.type === "main-column3") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
                
              const mainColumnMapping = (window as any).googletag.sizeMapping()
                .addSize([750, 0], [728, 90]) // if device width is larger than 750px, show 728x90 ad
                .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
                .build();
              this.slot.defineSizeMapping(mainColumnMapping);
          } else if (this.type === "sidebar-short") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [300, 250], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
          } else if (this.type === "sidebar-multi") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 600], [300, 250]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
  
              // const sidebarMultiMapping = (window as any).googletag.sizeMapping()
              //   .addSize([750, 0], [300, 600]) // if device width is larger than 750px, show 300x600 ad
              //   .addSize([0, 0], [300, 250]) // otherwise show 300x250 ad
              //   .build();
      
              // this.slot.defineSizeMapping(sidebarMultiMapping);
          } else if (this.type === "header") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90], [970, 90]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
                
              const fullWidthMapping = (window as any).googletag.sizeMapping()
                .addSize([1000, 0], [970, 90]) // if device width is larger than 1000px, show 970x90 ad
                .addSize([750, 0], [728, 90]) // otherwise if device width is larger than 750px, show 728x90 ad
                .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
                .build();
                this.slot.defineSizeMapping(fullWidthMapping);
          } else if (this.type === "masthead") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[970, 250]], this.adID)
                .addService((window as any).googletag.pubads());
  
          } else if (this.type === "home-POS1") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 250]], this.adID)
                .addService((window as any).googletag.pubads());
          } else if (this.type === "home-POS2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[728,90],[300,250]], this.adID)
                .addService((window as any).googletag.pubads());
  
            const mapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [[728, 90]])
              .addSize([780, 0], [[728, 90]])
              .addSize([480, 0], [[300, 250]])
              .addSize([360, 0], [[300, 250]])
              .addSize([320, 0], [[300, 250]])
              .build();
            this.slot.defineSizeMapping(mapping);
          } else if (this.type === "home-POS3") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 250]], this.adID)
              .addService((window as any).googletag.pubads());
          } else if (this.type === "bestbets-POS1") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[728,90],[300,250]], this.adID)
              .addService((window as any).googletag.pubads());
            const mapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [[728, 90]])
              .addSize([780, 0], [[728, 90]])
              .addSize([480, 0], [[300, 250]])
              .addSize([360, 0], [[300, 250]])
              .addSize([320, 0], [[300, 250]])
              .build();
            this.slot.defineSizeMapping(mapping);
          } else if (this.type === "bestbets-POS2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[728,90],[300,250]], this.adID)
              .addService((window as any).googletag.pubads());
            const mapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [[728, 90]])
              .addSize([780, 0], [[728, 90]])
              .addSize([480, 0], [[300, 250]])
              .addSize([360, 0], [[300, 250]])
              .addSize([320, 0], [[300, 250]])
              .build();
            this.slot.defineSizeMapping(mapping);
          } else if (this.type === "livenow-POS1") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[728,90],[300,250]], this.adID)
              .addService((window as any).googletag.pubads());
            const mapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [[728, 90]])
              .addSize([780, 0], [[728, 90]])
              .addSize([480, 0], [[300, 250]])
              .addSize([360, 0], [[300, 250]])
              .addSize([320, 0], [[300, 250]])
              .build();
            this.slot.defineSizeMapping(mapping);
          } else if (this.type === "livenow-POS2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 250]], this.adID)
              .addService((window as any).googletag.pubads());
          } else if (this.type === "horseracing-POS1") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 250]], this.adID)
              .addService((window as any).googletag.pubads());
          } else if (this.type === "horseracing-POS2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 600], [300, 250]], this.adID)
              .addService((window as any).googletag.pubads());
            const mapping = (window as any).googletag.sizeMapping()
              .addSize([1000, 0], [[300, 600]])
              .addSize([780, 0], [[300, 600]])
              .addSize([480, 0], [[300, 250]])
              .addSize([360, 0], [[300, 250]])
              .addSize([320, 0], [[300, 250]])
              .build();
            this.slot.defineSizeMapping(mapping);
          } else if (this.type === "articles-POS1") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[300, 250]], this.adID)
              .addService((window as any).googletag.pubads());
          } else if (this.type === "articles-POS2") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}` ,[[300, 600]], this.adID)
              .addService((window as any).googletag.pubads());
          } else if (["articles-POS3", "articles-POS4", "articles-POS5"].includes(this.type)) {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[728,90],[300,250]], this.adID)
              .addService((window as any).googletag.pubads());
              const mapping = (window as any).googletag.sizeMapping()
                .addSize([1000, 0], [[728, 90]])
                .addSize([780, 0], [[728, 90]])
                .addSize([480, 0], [[300, 250]])
                .addSize([360, 0], [[300, 250]])
                .addSize([320, 0], [[300, 250]])
                .build();
              this.slot.defineSizeMapping(mapping);
          } else if (this.type === "footer") {
              this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50], [728, 90], [970, 90]], this.adID)
                .addService((window as any).googletag.pubads())
                .setTargeting("Sport", this.sportCode?.toLowerCase() || "generic");
                
              const fullWidthMapping = (window as any).googletag.sizeMapping()
                .addSize([1000, 0], [970, 90]) // if device width is larger than 1000px, show 970x90 ad
                .addSize([750, 0], [728, 90]) // otherwise if device width is larger than 750px, show 728x90 ad
                .addSize([0, 0], [320, 50]) // otherwise show 320x50 ad
                .build();
              this.slot.defineSizeMapping(fullWidthMapping);
          } else if (this.type === "anchor") {
            this.slot = (window as any).googletag.defineSlot(`/${environment.googleAdsConfig.customerID}/${this.adObject.name}`, [[320, 50]], this.adID)
              .addService((window as any).googletag.pubads())
          }
  
  
          if (this.slot) {
            if (environment.optionalFeatures.includes("auctioned-ads")) {
              this.parallelAuction([this.slot]);
            }
  
            
            (window as any).googletag.display(this.adID);
          }
        });
      }
      
    }
  }

  ngOnDestroy() {
    if (typeof window !== "undefined" && this.slot) {
      this.clearAd();
    }

    if (this.routerWatch) {
      this.routerWatch.unsubscribe();
    }
  }

  clearAd() {
    (window as any).googletag.cmd.push(() => {
      (window as any).googletag.destroySlots([this.slot]);
    })
  }

  parallelAuction(gptSlots) {


    // 2g-ii. convert GPT slots to match required APS fetchBids schema
    // note: sizes used for APS are the sizes from the GPT size map for the *current viewport*
    // slots with sizes that are not available for the current viewport are filtered out!
    var apsSlots = gptSlots.map(function (gptSlot) {
      return {
        slotID: gptSlot.getSlotElementId(),
        slotName: gptSlot.getAdUnitPath(),
        sizes: gptSlot.getSizes(window.innerWidth, window.innerHeight).map(function (size) {
          return size !== 'fluid' ? [size.getWidth(), size.getHeight()] : [];
        })
      }
    }).filter(function (apsSlot) {
      return apsSlot.sizes.length;
    });

    // 2g-iii. define a callback for APS to fire after the APS auction has finished
    var apsCallback = () => {
      (window as any).apstag.setDisplayBids();						// assigns APS targeting to GPT slots
      this.requestManager.aps = true;					// tells requestManager that APS is finished
      this.biddersBack(gptSlots);											// attempt to call ad-server
    };

    // 2g-iv. request bids through APS
    (window as any).apstag.fetchBids(
      { slots: apsSlots },
      apsCallback
    );

    // 2g-v. define a callback for DM to fire after the DM auction has finished
    var dmCallback = () => {
      (window as any).pbjs.setTargetingForGPTAsync();			// assigns DM targeting to GPT slots
      this.requestManager.dm = true;						// tells requestManager that DM is finished
      this.biddersBack(gptSlots);											// attempt to call ad-server
    };

    // 2g-vi. request bids through DM
    (window as any).pbjs.que.push(function () {
      (window as any).pbjs.rp.requestBids({
        gptSlotObjects: gptSlots,
        callback: dmCallback
      });
    });

    // 2g-xi. failsafe timeout to call ad-server if something goes wrong
    setTimeout(() => {
      this.sendAdserverRequest(true, gptSlots);
    }, (window as any).FAILSAFE_TIMEOUT);

  }

  // 2g-vii. function to call ad-server for header bidding slots
  sendAdserverRequest(e, gptSlots) {
    if (!this.requestManager.adserverRequestSent) {
      if (e) { console.log('MGNI DEBUG * header bidding auction timed out'); };
      this.requestManager.adserverRequestSent = true;
      (window as any).googletag.pubads().refresh(gptSlots);
    }
  }

  // 2g-viii. function to attempt to call ad-server if both APS and DM auctions are finished
  biddersBack(gptSlots) {
    if (this.requestManager.aps && this.requestManager.dm) {
      this.sendAdserverRequest(null, gptSlots);
    }
  }


}
