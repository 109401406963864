import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContributorSummaryComponent } from './contributor-summary/contributor-summary.component';
import { FuturesSnapshotComponent } from './futures-snapshot/futures-snapshot.component';
import { LatestArticlesListComponent } from './latest-articles-list/latest-articles-list.component';
import { NextValueComponent } from './next-value/next-value.component';
// import { SocialButtonCollectionComponent } from './social-button-collection/social-button-collection.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { GeneralModule } from '../general/general.module';
import { UpcomingMatchesListComponent } from './upcoming-matches-list/upcoming-matches-list.component';
// import { TwitterEmbedComponent } from './twitter-embed/twitter-embed.component';
import { RacingToolsTutorialComponent } from './racing-tools-tutorial/racing-tools-tutorial.component';
import { UpcomingRaceMeetingsComponent } from './upcoming-race-meetings/upcoming-race-meetings.component';
import { NextRacesListComponent } from './next-races-list/next-races-list.component';
import { FeaturedRaceComponent } from './featured-race/featured-race.component';
import { PlayerRatingsSnapshotComponent } from './player-ratings-snapshot/player-ratings-snapshot.component';
import { TrueKickerSnapshotComponent } from './true-kicker-snapshot/true-kicker-snapshot.component';
import { BookOffersComponent } from './book-offers/book-offers.component';
import { HowWeMakeMoneyComponent } from './how-we-make-money/how-we-make-money.component';
import { NewsletterSignupPromptComponent } from './newsletter-signup-prompt/newsletter-signup-prompt.component';
import { FormsModule } from '@angular/forms';
import { FuturesMiniBlockComponent } from './futures-mini-block/futures-mini-block.component';
import { LoginPromptComponent } from './login-prompt/login-prompt.component';
import { BookOfferCardComponent } from './book-offer-card/book-offer-card.component';
import { RadarLinkComponent } from './radar-link/radar-link.component';
import { LegalBettingComponent } from './legal-betting/legal-betting.component';




@NgModule({
  declarations: [
	ContributorSummaryComponent,
	FuturesSnapshotComponent,
	LatestArticlesListComponent,
	NextValueComponent,
	// SocialButtonCollectionComponent,
	UpcomingMatchesListComponent,
	// TwitterEmbedComponent,
	RacingToolsTutorialComponent,
	UpcomingRaceMeetingsComponent,
	NextRacesListComponent,
	FeaturedRaceComponent,
	PlayerRatingsSnapshotComponent,
	TrueKickerSnapshotComponent,
	BookOffersComponent,
	HowWeMakeMoneyComponent,
	NewsletterSignupPromptComponent,
	FuturesMiniBlockComponent,
	LoginPromptComponent,
	BookOfferCardComponent,
	RadarLinkComponent,
	LegalBettingComponent,
  ],
  imports: [
	CommonModule,
	FontAwesomeModule,
	RouterModule,
	GeneralModule,
	FormsModule,
  ],
  schemas: [
	CUSTOM_ELEMENTS_SCHEMA,
  ],
  exports: [
	ContributorSummaryComponent,
	FuturesSnapshotComponent,
	LatestArticlesListComponent,
	NextValueComponent,
	// SocialButtonCollectionComponent,
	UpcomingMatchesListComponent,
	// TwitterEmbedComponent,
	RacingToolsTutorialComponent,
	UpcomingRaceMeetingsComponent,
	NextRacesListComponent,
	FeaturedRaceComponent,
	PlayerRatingsSnapshotComponent,
	TrueKickerSnapshotComponent,
	BookOffersComponent,
    LegalBettingComponent,
	HowWeMakeMoneyComponent,
	NewsletterSignupPromptComponent,
	LoginPromptComponent,
	BookOfferCardComponent,
	RadarLinkComponent,
	LegalBettingComponent,
  ]
})
export class SidebarModule { }
